import React from 'react'
import {client} from '../../apollo/client'
// @material-ui/core components
import List from '@material-ui/core/List'
import Ziptype from './Ziptype'
import Brands from './Brands/Brands'
import Price from './Price'
import OrderByChooserHook from './OrderByChooserHook'
import ScaleChooserHook from './ScaleChooserHook'
import AfilCountryChooserHook from './AfilCountryChooserHook'
import Divider from './Divider'
import {Paper, withStyles} from '@material-ui/core'
import PropTypes from 'prop-types'
import searchBarStyle from './searchBarStyle'
import Temperature from './Temperature'

const humanTypes = [{humanType: 'men'}, {humanType: 'women'}, {humanType: 'kids'}]
const scaleOptions = [{scale: 'cm'}, {scale: 'inch'}]

class SearchBarWetsuitFinder extends React.Component {
    constructor(props) {
        super(props)
        // we use this to make the card to appear after the page has been rendered
        this.state = {
            cardAnimaton: 'cardHidden',
            activeScaleLength: 'cm',
            activeScaleWeight: 'kg',
            humanType: 'men',
            activeScale: 'cm',

            height: '',
            heightInch: '',

            weight: '',
            weightPounds: '',

            chest: '',
            chestInch: '',

            waist: '',
            waistInch: '',

            neck: '',
            neckInch: '',


        }
    }

    async componentDidMount() {
        // we add a hidden class to the card and after 700 ms we delete it and the transition appears
        setTimeout(
            function () {
                this.setState({cardAnimaton: ''})
            }.bind(this),
            50,
        )

    }

    handleChangeInStateForm = prop => event => {

        this.setState({
            [prop]: event.target.value,
        })

        client.writeData(
            {
                data: {[prop]: event.target.value},
            },
        )

        console.log(prop, event.target.value)

        // this.props.searchTermEntered(prop, event.target.value);

    }


    handleTriggerSearch = async () => {


        this.props.triggerSearch({

            activeScale: this.state.activeScale,

        })


    }

    triggerSearch = async () => {

    }


    render() {

        const {classes, transl, searchSizesOnly, showButton} = this.props
        return (

            <Paper root={classes.paper}>
                {/*{showButton ? <SearchButtonFullWidth*/}
                {/*  onClick={() => {*/}
                {/*    TriggerSearchBar()*/}
                {/*  }}*/}
                {/*  searchButtonText={labels.searchButtonText}*/}
                {/*/> : null}*/}
                <List
                    component="nav"
                    aria-labelledby="nested-list-subheader"
                    // subheader={
                    //   <ListSubheader component="div" id="nested-list-subheader">
                    //     Find the one
                    //   </ListSubheader>
                    // }
                    className={classes.listContainer}
                >

                    <div style={{
                        width: '100%',
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'center',
                        alignItems: 'center',
                        flexWrap: 'wrap',
                    }}>
                        {!searchSizesOnly ?
                            <AfilCountryChooserHook
                                title={transl.countryDropdownText}
                            /> : null}
                        {!searchSizesOnly ?
                            <ScaleChooserHook
                                title={transl.metricDropDownText}
                            />: null}

                        {!searchSizesOnly ?
                            <OrderByChooserHook
                                title={transl.sortProductsDropdownTitle}
                                sortProductsItemNewProductsText={transl.sortProductsItemNewproducts}
                                sortProductsItemLowestPriceText={transl.sortProductsItemLowestprice}
                                sortProductsItemHighestPriceText={transl.sortProductsItemHeighestprice}

                            />
                            : null}
                    </div>


                    {!searchSizesOnly ? <Divider/> : null}

                    {/*<HumanType*/}
                    {/*  title={labels.searchSectionGender}*/}
                    {/*  searchSectionGenderLabelMen={labels.searchSectionGenderLabelMen}*/}
                    {/*  searchSectionGenderLabelWomen={labels.searchSectionGenderLabelWomen}*/}
                    {/*  searchSectionGenderLabelKids={labels.searchSectionGenderLabelKids}*/}
                    {/*/>*/}

                    {/*<Divider/>*/}

                    {/*<DimensionsHook*/}
                    {/*  title={labels.searchSectionDimensions}*/}
                    {/*  searchSectionDimensionsChest={labels.searchSectionDimensionsChest}*/}
                    {/*  searchSectionDimensionsHeight={labels.searchSectionDimensionsHeight}*/}
                    {/*  searchSectionDimensionsHips={labels.searchSectionDimensionsHips}*/}
                    {/*  searchSectionDimensionsWaist={labels.searchSectionDimensionsWaist}*/}
                    {/*  searchSectionDimensionsWeight={labels.searchSectionDimensionsWeight}*/}
                    {/*/>*/}

                    {/*<Divider/>*/}
                    {!searchSizesOnly ? <>
                        <Ziptype
                            title={transl.searchSectionZiptypeTitle}
                            searchSectionZipTypeFrontZip={transl.searchSectionZiptypeFrontzip}
                            searchSectionZipTypeBackZip={transl.searchSectionZiptypeBackzip}
                            searchSectionZipTypeNoZip={transl.searchSectionZiptypeNozip}
                        />

                        <Divider/>
                    </> : null}


                    <Brands
                        title={transl.searchSectionBrandsTitle}
                    />
                    {!searchSizesOnly ?
                        <>
                            <Divider/>

                            < Price
                                title={transl.searchSectionPriceTitle}
                            />

                            <Divider/>

                            <Temperature
                                title={transl.searchSectionTemperatureTitle}
                                searchSectionTemperatureAll={transl.searchSectionTemperatureAll}
                                searchSectionTemperatureTropics={transl.searchSectionTemperatureTropics}
                                searchSectionTemperatureSummer={transl.searchSectionTemperatureSummer}
                                searchSectionTemperatureAutumn={transl.searchSectionTemperatureAutumn}
                                searchSectionTemperatureWinter={transl.searchSectionTemperatureWinter}

                            /> </> : null}
                </List>
            </Paper>

        )
    }
}

SearchBarWetsuitFinder.defaultProps = {
    searchSizesOnly: false,
    showButton: true,
}
SearchBarWetsuitFinder.propTypes = {
    searchSizesOnly: PropTypes.bool,
    showButton: PropTypes.bool,

}
export default withStyles(searchBarStyle)(SearchBarWetsuitFinder)
