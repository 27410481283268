import React from 'react'
import Footer from '../../components/Footer/Footer'
import { graphql } from 'gatsby'
// import BlogSection from '../../elements/RecentBlogPosts/RecentBlogPosts'
import { client } from '../../apollo/client'
import withStyles from '@material-ui/core/styles/withStyles'
import sizeFinderStyle from './SizeFinderStyle'
// Sections for this page
import _ from 'lodash'
import DisplayBrands from './DisplayBrands'
import { FULL_MATCH_SIZES } from '../../apollo/gql/FULL_MATCH_SIZES'
import { FULL_MATCH_SIZES_INCH } from '../../apollo/gql/FULL_MATCH_SIZES_INCH'
import { CACHE_STATE } from '../../apollo/gql/CACHE_STATE'
import MainRight from '../../components/FinderPage/MainRight'
import SidebarLeft from '../../components/FinderPage/SidebarLeft'
import SearchBarProvider from '../../elements/SearchBar/SearchBarProvider'
import PageHeaderFullHalf from '../../components/PageHeaderFullHalf/PageHeaderFullHalf'
import Loader from '../WetsuitFinder/Loader'
import Section from '../../components/Section/Section'
import HeaderProvider from '../../components/Header/HeaderProvider'
import WetsuitFinderContent from "../WetsuitFinder/WetsuitFinderContent";
import PageHeaderFinder from "../../components/PageHeaderFinder/PageHeaderFinder";
import TriggerSearchBar from "../WetsuitFinder/TriggerSearchBar";
import InstagramSection from "../../components/InstagramSection/InstagramSection";
import SpainHolidaysCta from "../../elements/SpainholidaysCta/SpainHolidaysCta";
import SEO from '../../components/SEO'


class SizeFinder extends React.Component {

  constructor(props) {
    super(props)
    // we use this to make the card to appear after the page has been rendered
    this.state = {

      humanType: 'MEN',
      activeScale: 'CM',

      heightMin: 170,
      heightMax: 185,
      height: 180,
      weight: 90,

      activeBrands: [],
      toggledBrands: ['mystic'],

      results: null,

    }
  }

  triggerSearch = async () => {
    const cache = await client.query({
      query: CACHE_STATE,
    })

    this.setState({
      activeScale: cache.data.activeScale,
      humanType: cache.data.humanType.toUpperCase(),
    })

    let search_result

    const humanType = cache.data.humanType.toUpperCase()
    const orderBy = cache.data.orderBy

    const priceMin = parseInt(cache.data.priceMin)
    const priceMax = parseInt(cache.data.priceMax)

    const frontzip = cache.data.frontzip
    const backzip = cache.data.backzip
    const nozip = cache.data.nozip

    let height = cache.data.height ? parseInt(cache.data.height) : 999
    let heightMin = cache.data.height ? parseInt(cache.data.height) - 2.5 : 999
    let heightMax = cache.data.height ? parseInt(cache.data.height) + 2.5 : 999
    if (cache.data.activeScale === 'INCH') {
      const heightFeet = cache.data.heightFeet ? parseInt(cache.data.heightFeet) * 12 : 999
      const heightInch = cache.data.heightInch ? parseInt(cache.data.heightInch) : 0
      height = heightFeet + heightInch
      heightMin = heightFeet + heightInch - 0.984252
      heightMax = heightFeet + heightInch + 0.984252
    }

    const weight = cache.data.weight ? parseInt(cache.data.weight) : 999
    let weightMin = cache.data.weight ? parseInt(cache.data.weight) - 4 : 999
    let weightMax = cache.data.weight ? parseInt(cache.data.weight) + 4 : 999

    if (cache.data.activeScale === 'INCH') {
      weightMin = cache.data.weight ? parseInt(cache.data.weight) - 8.81849 : 999
      weightMax = cache.data.weight ? parseInt(cache.data.weight) + 8.81849 : 999
    }

    const chest = cache.data.chest ? parseInt(cache.data.chest) : 999
    let chestMin = cache.data.chest ? parseInt(cache.data.chest) - 2 : 999
    let chestMax = cache.data.chest ? parseInt(cache.data.chest) + 2 : 999
    if (cache.data.activeScale === 'INCH') {
      chestMin = cache.data.chest ? parseInt(cache.data.chest) - 0.787402 : 999
      chestMax = cache.data.chest ? parseInt(cache.data.chest) + 0.787402 : 999
    }

    const waist = cache.data.waist ? parseInt(cache.data.waist) : 999
    let waistMin = cache.data.waist ? parseInt(cache.data.waist) - 2 : 999
    let waistMax = cache.data.waist ? parseInt(cache.data.waist) + 2 : 999
    if (cache.data.activeScale === 'INCH') {
      waistMin = cache.data.waist ? parseInt(cache.data.waist) - 0.787402 : 999
      waistMax = cache.data.waist ? parseInt(cache.data.waist) + 0.787402 : 999
    }

    const hips = cache.data.hips ? parseInt(cache.data.hips) : 999
    let hipsMin = cache.data.hips ? parseInt(cache.data.hips) - 2 : 999
    let hipsMax = cache.data.hips ? parseInt(cache.data.hips) + 2 : 999
    if (cache.data.activeScale === 'INCH') {
      hipsMin = cache.data.hips ? parseInt(cache.data.hips) - 0.787402 : 999
      hipsMax = cache.data.hips ? parseInt(cache.data.hips) + 0.787402 : 999
    }


    const allTemperatures = cache.data.allTemperatures
    const tropics = cache.data.tropics
    const summer = cache.data.summer
    const autumn = cache.data.autumn
    const winter = cache.data.winter

    const mystic = cache.data.mystic
    const orca = cache.data.orca
    const billabong = cache.data.billabong
    const hurley = cache.data.hurley
    const ion = cache.data.ion
    const neilpryde = cache.data.neilpryde
    const oneill = cache.data.oneill
    const quiksilver = cache.data.quiksilver
    const ripCurl = cache.data.ripCurl
    const roxy = cache.data.roxy
    const vissla = cache.data.vissla
    const xcel = cache.data.xcel
    const aquaSphere = cache.data.aquaSphere
    const arena = cache.data.arena
    const patagonia = cache.data.patagonia
    const productAmount = this.state.productAmount
    const afilCountry = cache.data.activeAfilCountry


    const variables = {
      orderBy,
      productAmount,

      humanType,
      afilCountry,

      priceMin,
      priceMax,

      frontzip,
      backzip,
      nozip,

      height,
      heightMin,
      heightMax,

      weight,
      weightMin,
      weightMax,

      chest,
      chestMin,
      chestMax,

      waist,
      waistMin,
      waistMax,

      hips,
      hipsMin,
      hipsMax,

      mystic,
      orca,
      billabong,
      hurley,
      ion,
      neilpryde,
      oneill,
      quiksilver,
      ripCurl,
      roxy,
      vissla,
      xcel,
      aquaSphere,
      arena,
      patagonia,
    }

    console.log('SEARCH:', variables,
    )


    let query
    query =
      cache.data.activeScale === 'INCH' ? FULL_MATCH_SIZES_INCH : FULL_MATCH_SIZES

    search_result = await client.query({
      query,
      variables,
    })

    let group = await _.groupBy(search_result.data.sizes, function(currentObject) {
      return currentObject.brand.name
    })

    let foo = []
    const wait = await _.mapKeys(group, function(value, key) {
      let foofoo = { brand: key, sizes: value }
      foo.push(foofoo)
      // console.log('key', key, value)

    })

    this.setState({
      results: foo,
    })
    console.log('foo', foo)
    console.log('cache', cache.data)
    console.log('search_result', search_result)
    console.log('group', group)
    console.log('ori', search_result.data.sizes)
  }
  triggerSearchBar = async () => {

    this.setState({
      results: null,
      hasNextPage: false,

    }, async () => {

      client.writeData(
        {
          data: { loading: true },
        },
      )
      try {
        await this.triggerSearch()

        client.writeData(
          {
            data: {
              loading: false,

            },
          },
        )
      } catch (e) {
        client.writeData(
          {
            data: {
              loading: false,

            },
          },
        )
      }


    })

  }

  render() {
    const { classes, data } = this.props
    const { results } = this.state
    const modified = []
    data.datoCmsSizeFinder._allUrlLocales.map(item => {
      if (item.locale === 'de') {
        modified.push({ 'locale': item.locale, 'value': item.value })
      } else {
        modified.push({ 'locale': item.locale, 'value': item.value })
      }
    })

    return (
      <div className={classes.backgroundColor}>
        <SEO
          seoMetaTags={data.datoCmsSizeFinder.seoMetaTags.tags}
          actLang={this.props.pageContext.locale}
          localURLS={modified}
        />
        <HeaderProvider
          localURLS={data.datoCmsSizeFinder._allUrlLocales}
          actLang={this.props.pageContext.locale}
          SEOtitle={data.datoCmsSizeFinder.pageTitle}
          SEOdescription={data.datoCmsSizeFinder.pageSubtitle}
          SEOimage={data.datoCmsSizeFinder.headerImage.fluid.src}
          light
          absolute
          compact
        />
        {/*on mobil have header here with prop COMPACT!!!*/}
        <PageHeaderFinder
          leftHeader
          image={data.datoCmsSizeFinder.headerImage.fluid}
          title={data.datoCmsSizeFinder.pageTitle}
          // subtitle={data.datoCmsSizeFinder.pageSubtitle}
          alt={data.datoCmsSizeFinder.headerImage.alt}
          transl={data.datoCmsTranslationMain}
          onSBClick={this.triggerSearchBar}
        />
        <Section
          smallLeftRightPadding
          paddingTop={40}
          flexDirRow={true}

          // backgroundColor={'khaki'}
        >
          <SidebarLeft>
            <SearchBarProvider
              searchSizesOnly={true}
              onSBClick={this.triggerSearchBar}
              transl={data.datoCmsTranslationMain}
            />
          </SidebarLeft>
          <MainRight>
            <Loader data={results}/>
            {results ?
              <DisplayBrands
                inch={this.state.activeScale === 'INCH'}
                activeScale={this.state.activeScale}
                ht={this.state.humanType.toUpperCase()}
                data={results}
                actLang={this.props.pageContext.locale}
              /> : null}
          </MainRight>
        </Section>

        <InstagramSection/>
        <SpainHolidaysCta
            title={data.datoCmsHome.bannerBlank[0].title}
            subtitle={data.datoCmsHome.bannerBlank[0].subtitle}
            buttonTitle={data.datoCmsHome.bannerBlank[0].buttonTitle}
            buttonLink={data.datoCmsHome.bannerBlank[0].buttonLink}
        />
        <Footer
          actLang={this.props.pageContext.locale}
        />
      </div>
    )
  }
}

export default withStyles(sizeFinderStyle)(SizeFinder)

export const data = graphql`
    query($locale: String!) {
        datoCmsSizeFinder(locale:{eq:$locale})   {
            seoMetaTags{
                tags
            }
            _allUrlLocales{locale value}
            pageTitle
            pageSubtitle
            headerImage{
                alt
                fluid(maxWidth:1800){
                    src
                    ...GatsbyDatoCmsFluid_noBase64
                }
            }


        }
        datoCmsTranslationMain (locale:{eq:$locale}){
            metricDropDownText
            mobileSearchMenubuttonLabel
            searchButtonTitle
            searchSectionBrandsTitle
            searchSectionDimensionsChest
            searchSectionDimensionsHeight
            searchSectionDimensionsTitle
            searchSectionDimensionsHips
            searchSectionDimensionsWaist
            searchSectionDimensionsWeight
            searchSectionGenderKids
            sortProductsItemNewproducts
            sortProductsItemLowestprice
            sortProductsItemHeighestprice
            sortProductsDropdownTitle
            showMoreButtonLabel
            searchSectionZiptypeTitle
            searchSectionZiptypeNozip
            searchSectionZiptypeFrontzip
            searchSectionZiptypeBackzip
            searchSectionTemperatureWinter
            searchSectionTemperatureTropics
            searchSectionTemperatureTitle
            searchSectionTemperatureSummer
            searchSectionTemperatureAutumn
            searchSectionTemperatureAll
            searchSectionPriceTitle
            searchSectionGenderWomen
            searchSectionGenderTitle
            searchSectionGenderMen
            countryDropdownText
        }
      datoCmsHome(locale:{eq:$locale}) {

        
        
        bannerBlank{
          title
          subtitle
          buttonLink
          buttonTitle
        }
      }
    }
`