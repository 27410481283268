import React from 'react'
import withStyles from '@material-ui/core/styles/withStyles'
import TableCell from '@material-ui/core/TableCell'


const styles = {
  root: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  wrapper: {
    width: '100%',
    display: 'flex',
    flex: 1,
    maxWidth: '100%',
    height: 60,
    marginBottom: 15,
  },
}


const CellSingle = props => {

  const { classes, val } = props
  return (
    <TableCell align="center">
      {props.bold ? <span style={{ fontWeight: 'bold', padding: 5, color: 'white', backgroundColor: '#1a1a1a' }}>
        {val ?
          val : null
        }
      </span> :
        <>
          {val ?
            val : null
          }
        </>}

    </TableCell>

  )
}

CellSingle.defaultProps = {
  val: null,
  bold: false,
}

export default withStyles(styles)(CellSingle)
