export const StartSearchingStyle = {
    root: {
        display: 'flex',

        width: '100%',
        flex: 1,
        justifyContent: 'center',
        // backgroundColor: '#dce775',
        flexDirection: 'row',
        flexWrap: 'wrap',
    },
    noResultswrapper: {
        width: '100%',
        display: 'flex',
        paddingTop: 40,
        justifyContent: 'flex-start',
        alignItems: 'center',
        flexDirection: 'column',
    },
    noResultswrapperFont: {
        fontWeight: 'bold', marginBottom: 0, paddingBottom: 0
    },


}


