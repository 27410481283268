import React from 'react'
import withStyles from '@material-ui/core/styles/withStyles'
import searchBarStyle from './searchBarStyle'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import Collapse from '@material-ui/core/Collapse'

import ExpandLess from '@material-ui/icons/ExpandLess'
import ExpandMore from '@material-ui/icons/ExpandMore'
import WaveIcon from '@material-ui/icons/Waves'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import { client } from '../../apollo/client'
import ValuePreview from './ValuePreview/ValuePreview'
import { RadioGroup } from '@material-ui/core'
import Radio from '@material-ui/core/Radio'


class Temperature extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      open: false,
      setTemperature: 'all',
      allTemperatures: true,
      tropics: true,
      summer: true,
      autumn: true,
      winter: true,
    }
  }

  handleClick = () => {
    if (this.state.open) {
      this.setState({
        open: false,
      })
    } else {
      this.setState({
        open: true,
      })
    }
  }

  handleChange = prop => (event, value) => {
    console.log('value', value)
    this.setState({
      [prop]: value,
    })

    if (value === 'all') {
      client.writeData({
        data: {
          allTemperatures: true,
          tropics: true,
          summer: true,
          autumn: true,
          winter: true,
        },
      })
    }

    if (value === 'tropics') {
      client.writeData({
        data: {
          allTemperatures: false,
          tropics: true,
          summer: false,
          autumn: false,
          winter: false,
        },
      })
    }
    if (value === 'summer') {
      client.writeData({
        data: {
          allTemperatures: false,
          tropics: false,
          summer: true,
          autumn: false,
          winter: false,
        },
      })
    }
    if (value === 'autumn') {
      client.writeData({
        data: {
          allTemperatures: false,
          tropics: false,
          summer: false,
          autumn: true,
          winter: false,
        },
      })
    }
    if (value === 'winter') {
      client.writeData({
        data: {
          allTemperatures: false,
          tropics: false,
          summer: false,
          autumn: false,
          winter: true,
        },
      })
    }
    //
    // if (event.target.value === 'allTemperatures' && event.target.checked) {
    //   console.log('allTemperatures', event.target.checked)
    //   this.setState({
    //     ...this.state,
    //     allTemperatures: event.target.checked,
    //     tropics: true,
    //     summer: true,
    //     autumn: true,
    //     winter: true,
    //   })
    //
    //   client.writeData({
    //     data: {
    //       allTemperatures: event.target.checked,
    //       tropics: true,
    //       summer: true,
    //       autumn: true,
    //       winter: true,
    //     },
    //   })
    // } else {
    //   this.setState({
    //     ...this.state,
    //     tropics: false,
    //     all: false,
    //     summer: false,
    //     autumn: false,
    //     winter: false,
    //     [event.target.value]: event.target.checked,
    //   })
    //   client.writeData({
    //     data: {
    //       tropics: false,
    //       all: false,
    //       summer: false,
    //       autumn: false,
    //       winter: false,
    //       [event.target.value]: event.target.checked,
    //     },
    //   })
    // }
    //
    // if (event.target.value === 'allTemperatures' && !event.target.checked) {
    //   console.log('allTemperatures', event.target.checked)
    //   this.setState({
    //     ...this.state,
    //     allTemperatures: event.target.checked,
    //     tropics: false,
    //     summer: true,
    //     autumn: false,
    //     winter: false,
    //   })
    //   client.writeData({
    //     data: {
    //       allTemperatures: event.target.checked,
    //       tropics: false,
    //       summer: true,
    //       autumn: false,
    //       winter: false,
    //     },
    //   })
    // }


  }

  render() {
    const {
      classes,
      title,
      searchSectionTemperatureAll,
      searchSectionTemperatureTropics,
      searchSectionTemperatureSummer,
      searchSectionTemperatureAutumn,
      searchSectionTemperatureWinter,
    } = this.props
    const { open, setTemperature } = this.state

    return (
      <div style={{ color: 'black' }}>
        <ListItem button onClick={() => this.handleClick()}>
          <ListItemIcon>
            <WaveIcon/>
          </ListItemIcon>
          <ListItemText primary={title}/>
          {open ?
            <ValuePreview values={[
              {
                title:
                  setTemperature === 'all' ? searchSectionTemperatureAll :
                    setTemperature === 'tropics' ? searchSectionTemperatureTropics :
                      setTemperature === 'summer' ? searchSectionTemperatureSummer :
                        setTemperature === 'autumn' ? searchSectionTemperatureAutumn :
                          setTemperature === 'winter' ? searchSectionTemperatureWinter : '',

              },
            ]}>
              <ExpandLess style={{ color: 'gray' }}/>
            </ValuePreview> :
            <ValuePreview values={[
              {
                title:
                  setTemperature === 'all' ? searchSectionTemperatureAll :
                    setTemperature === 'tropics' ? searchSectionTemperatureTropics :
                      setTemperature === 'summer' ? searchSectionTemperatureSummer :
                        setTemperature === 'autumn' ? searchSectionTemperatureAutumn :
                          setTemperature === 'winter' ? searchSectionTemperatureWinter : '',
              },
            ]}>
              <ExpandMore style={{ color: 'gray' }}/>
            </ValuePreview>}
        </ListItem>
        <Collapse in={open} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <RadioGroup className={classes.dropdownListWrapper} value={setTemperature}
                        onChange={this.handleChange('setTemperature')}>
              <FormControlLabel value="all" control={<Radio color="primary"/>}
                                label={searchSectionTemperatureAll}
                                className={classes.dropdownListItem}/>
              <FormControlLabel value="tropics" control={<Radio color="primary"/>}
                                label={searchSectionTemperatureTropics + ' > 23°C (73,4°F)'}
                                className={classes.dropdownListItem}/>
              <FormControlLabel value="summer" control={<Radio color="primary"/>}
                                label={searchSectionTemperatureSummer + ' 15-23°C (53,6 - 73°F)'}
                                className={classes.dropdownListItem}/>
              <FormControlLabel value="autumn" control={<Radio color="primary"/>}
                                label={searchSectionTemperatureAutumn + ' 13-19°C (55,4 - 66,2°F)'}
                                className={classes.dropdownListItem}/>
              <FormControlLabel value="winter" control={<Radio color="primary"/>}
                                label={searchSectionTemperatureWinter + ' < 13°C (< 55,4°F)'}
                                className={classes.dropdownListItem}/>
            </RadioGroup>

          </List>
        </Collapse>
      </div>
    )
  }
}

export default withStyles(searchBarStyle)(Temperature)
