import React from 'react'
// import BlogSection from '../../elements/RecentBlogPosts/RecentBlogPosts'
import withStyles from '@material-ui/core/styles/withStyles'
import wetsuitResultsStyle from './wetsuitResultsStyle'
// Sections for this page
import classNames from 'classnames'
import Icon from '@material-ui/icons/SquareFoot'
import {CACHE_RESULTS} from '../../../apollo/gql/CACHE_RESULTS'
import {useQuery} from '@apollo/react-hooks'
import NothingFound from './NothingFound/NothingFound'
import StartSearching from "./StartSearching/StartSearching";

function WetsuitResults(props) {

    const {classes, actLang} = props

    const {loading, error, data} = useQuery(CACHE_RESULTS)

    if (loading) return 'Loading...'
    if (error) return `Error! ${error.message}`

    // console.log('CACHE_RESULTS', data)

    // return <h1>    {JSON.stringify(data)}</h1>
    //
    return (
        <div className={classes.root}>
            {/*{JSON.stringify(sizes)}*/}

            {data.results ? data.results.edges.length > 0 ? null : <NothingFound actLang={actLang}/> : null}
            {data.results ? null : <StartSearching actLang={actLang}/>}

            {data.results ?
                data.results.edges.map((wetsuit) => {
                    return (
                        <div className={classes.product}>
                            <a href={wetsuit.node.afilLink} target={'_blank'}
                               className={classes.productLinkWrapper}>
                                <img alt="..." src={wetsuit.node.smallImage}
                                     height={200}

                                     className={classes.image}/>

                                <div className={classes.priceBrandWrapper}>
                                    <p className={classes.productFont}
                                       style={{fontWeight: 'bold'}}
                                        // style={{border:"solid #a1a1a1 1px"}}
                                    >{wetsuit.node.brand.displayName}</p>

                                </div>
                                <div className={classes.priceBrandWrapper} style={{marginTop: 0}}>
                                    <div className={classes.SizeSubWrapper}>
                                        <p className={classes.productFont}
                                           style={{
                                               color: 'white',
                                               fontWeight: 'bold'
                                           }}> {actLang === "de" ? "Größe:" : "Size:"} {wetsuit.node.size.name}</p>
                                    </div>
                                </div>

                                <div className={classes.priceBrandWrapper}>
                                    <p
                                        className={classNames(classes.price, wetsuit.node.reducedPrice ? classes.priceReduced : null)}>
                                        {wetsuit.node.price}{wetsuit.node.currency === 'GBP' ? '£' : wetsuit.node.currency === 'EUR' ? '€' : ''}
                                    </p>
                                    {wetsuit.node.reducedPrice ?
                                        <div
                                            style={{padding: 5, marginRight: 10}}
                                        >
                                            <p className={classNames(classes.price, classes.oldPrice)}>
                                                {wetsuit.node.oldPrice}{wetsuit.node.currency === 'GBP' ? '£' : wetsuit.node.currency === 'EUR' ? '€' : ''}
                                            </p>
                                        </div> : null
                                    }
                                </div>
                                <div className={classes.productNameWrapper}>
                                    <p className={classes.productName} style={{letterSpacing: 1, fontSize: 14}}>
                                        {wetsuit.node.productName}
                                        {
                                            wetsuit.node.frontzip ||
                                            wetsuit.node.backzip ||
                                            wetsuit.node.zipless ? <span
                                                className={classes.productNameWrapperSpan}>
                     {wetsuit.node.frontzip ? 'Frontzip' : null}
                                                {wetsuit.node.backzip ? 'Backzip' : null}
                                                {wetsuit.node.zipless ? 'Zipless' : null}
                    </span> : null
                                        }
                                    </p>
                                </div>
                                <div className={classes.afilPartnerContainer}>
                                    <div className={classes.afilPartnerWrapper}>
                                        <p className={classNames(classes.productName, classes.chipFont)}
                                           style={{
                                               margin: 0,
                                               padding: 0,

                                               fontWeigth: 'bold',
                                               textTransform: 'uppercase',
                                           }}
                                            // style={{border:"solid #a1a1a1 1px"}}
                                        >↝ {wetsuit.node.afilPartner.slice(0, wetsuit.node.afilPartner.length - 3)}</p>
                                    </div>
                                </div>
                            </a>
                            <div style={{backgroundColor: '#1a1a1a', marginTop: 20}}>

                                <div className={classes.sizeAndChartLinkWrapperItem}>
                                    <a target="_blank"
                                       rel="noopener noreferrer"
                                       href={
                                           actLang === 'de' ?
                                               '/de/groessentabellen/' + wetsuit.node.brand.name.replace(/\s/g, '-').replace(/'/g, '-') :
                                               '/sizecharts/' + wetsuit.node.brand.name.replace(/\s/g, '-').replace(/'/g, '-')
                                       }
                                       className={classes.sizeAndChartLinkWrapperItemLink}
                                    >
                                        <p
                                            className={classNames(classes.productName, classes.chipFont)}
                                            style={{color: 'white', margin: 0, padding: 0}}>
                                            {actLang === 'de' ? 'Größentabelle' : 'Sizechart'}</p>
                                        <Icon style={{marginLeft: 6, marginTop: -2, fontSize: 14, color: 'white'}}/>
                                    </a>
                                </div>

                                {/*<div style={{ backgroundColor: '#1a1a1a', padding: 5, borderRadius: 99 }}>*/}
                                {/*  <InfoIcon style={{ color: 'white' }}/>*/}
                                {/*</div>*/}

                            </div>


                        </div>
                    )

                }) : null
            }

        </div>

    )

}

// export default WetsuitResults
export default withStyles(wetsuitResultsStyle)(WetsuitResults)
