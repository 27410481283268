import React from 'react'
// import BlogSection from '../../elements/RecentBlogPosts/RecentBlogPosts'
import withStyles from '@material-ui/core/styles/withStyles'
// Sections for this page
import IconUnhappy from '@material-ui/icons/EmojiPeople'
import {CACHE_RESULTS} from '../../../../apollo/gql/CACHE_RESULTS'
import {useQuery} from '@apollo/react-hooks'
import {StartSearchingStyle} from "./startSearchingStyle";

function StartSearching(props) {

    const {classes, actLang} = props

    const {loading, error, data} = useQuery(CACHE_RESULTS)

    if (loading) return 'Loading...'
    if (error) return `Error! ${error.message}`

    // console.log('CACHE_RESULTS', data)

    // return <h1>    {JSON.stringify(data)}</h1>
    //
    return (
        <div className={classes.root}>
            {/*{JSON.stringify(sizes)}*/}
            {
                actLang === 'de' ?
                    <div className={classes.noResultswrapper}>
                        <IconUnhappy fontSize={'large'}/>
                        <p className={classes.noResultswrapperFont}>Gib deine Größe und dein Gewicht ein und los gehts!</p>
                        <p className={classes.noResultswrapperFont}>↝ Finde heraus welcher Online Shop deinen perfekten Neoprenanzug bereithält</p>
                    </div> :
                    <div className={classes.noResultswrapper}>
                        <IconUnhappy fontSize={'large'}/>
                        <p className={classes.noResultswrapperFont}>Just enter your height and your weight</p>
                        <p className={classes.noResultswrapperFont}>↝ Find out which online shop has your perfect wetsuit in stock</p>
                    </div>
            }
        </div>

    )

}

// export default WetsuitResults
export default withStyles(StartSearchingStyle)(StartSearching)
