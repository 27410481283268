import React from 'react'
import {makeStyles} from '@material-ui/core/styles'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import {client} from '../../apollo/client'
import {useQuery} from '@apollo/react-hooks'
import {CACHE_STATE} from '../../apollo/gql/CACHE_STATE'

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
        height: "100%",
        justifyContent: "center",
        alignItems: "center",

    },
    formControl: {
        height: "100%",
        minWidth: 100,
        width: '100%',
        margin: 0,
    },
    selectEmpty: {},
}))

export default function HumanTypeChooser(props) {
    const classes = useStyles()

    const [values, setValues] = React.useState({
        humanType: 'MEN',
        name: 'humanType',
    })

    const inputLabel = React.useRef(null)
    const [labelWidth, setLabelWidth] = React.useState(0)
    React.useEffect(() => {
        setLabelWidth(inputLabel.current.offsetWidth)
    }, [])

    const handleChange = event => {
        setValues(oldValues => ({
            ...oldValues,
            [event.target.name]: event.target.value,
        }))

        client.writeData(
            {
                data: {humanType: event.target.value},
            },
        )
    }

    const {transl} = props
    const {loading, error, data} = useQuery(CACHE_STATE)
    return (
        <form className={classes.root} autoComplete="off">
            <FormControl className={classes.formControl}>
                <InputLabel ref={inputLabel} htmlFor="outlined-humanType-simple">
                    {transl.searchSectionGenderTitle}
                </InputLabel>
                <Select
                    value={data ? data.humanType : values.humanType}
                    onChange={handleChange}

                    inputProps={{
                        name: 'humanType',
                        id: 'outlined-humanType-simple',
                    }}
                >
                    {[{humanType: 'men', label: transl.searchSectionGenderMen},
                        {humanType: 'women', label: transl.searchSectionGenderWomen},
                        {humanType: 'kids', label: transl.searchSectionGenderKids}].map(option => (
                        <MenuItem value={option.humanType}
                        >{option.label}</MenuItem>
                    ))}
                </Select>
            </FormControl>

        </form>
    )
}
