import { H1, H3 } from 'assets/jss/material-kit-react.jsx'


const pageHeaderFullHalfStyle = {
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '70vh',
    width: '100%',
  },

  parallax: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flex: 1,
    height: '100%',


    overflow: 'hidden',

    backgroundPosition: 'center center',
    backgroundSize: 'cover',
    margin: '0',
    padding: '0',
    border: '0',

  },
  filter: {
    '&:before': {
      // background: 'rgba(159, 159, 159, 0.1)',
      // background: 'rgba(0, 0, 0, 0.09)',
      // background: 'rgba(215, 215, 215, 0.2)',
      // background: 'rgba(192, 197, 210, 0.12)',
    },
    '&:after,&:before': {
      position: 'absolute',
      zIndex: '1',
      width: '100%',
      height: '100%',
      display: 'block',
      left: '0',
      top: '0',
      content: '\'\'',
    },
  },
  headerTitle: {
    ...H1,
    color: '#fff',
    textTransform: 'none',
    fontWeight: 'bold',
  },

  headerSubTitle: {
    ...H3,
    color: '#fff',
    textTransform: 'none',
    fontWeight: 'bold',

    // color: '#fff',
    // textTransform: 'none',
    //
    // fontWeight: 'bold',
  },

  container: {
    display: 'flex',
    flex: 1,
    width: '100%',
    flexDirection: 'column',
    zIndex: '2',
    color: '#FFFFFF',
    position: 'absolute',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',

  },
  scrollDown: {
    display: 'flex',

    width: '100%',

    zIndex: '2',

    position: 'absolute',

    justifyContent: 'center',

    bottom: 50,
  },
  centered: {
    justifyContent: 'center',
    textAlign: 'center',
  },
  logo: {
    position: 'absolute',
    zIndex: 12,
    top: 125,
    width: '100%',
    fontSize: 36,
    color: '#fff',
    textAlign: 'center',
    transition: 'all .4s ease',
    fontFamily: '\'Poppins\', sans-serif',
    fontWeight: 500,
    textTransform: 'none',
    letterSpacing: -2,
  },
}

export default pageHeaderFullHalfStyle
