import React from 'react'
// import BlogSection from '../../elements/RecentBlogPosts/RecentBlogPosts'
// Sections for this page
import { useQuery } from '@apollo/react-hooks'
import withStyles from '@material-ui/core/styles/withStyles'
// import { SIZES } from './HeightWeight'
import { Tab, Tabs } from '@material-ui/core'
import { CACHE_STATE } from '../../apollo/gql/CACHE_STATE'
import { client } from '../../apollo/client'

const styles = {
  root: {
    margin: 0,
    padding: 0,
    display: 'flex',
    borderRadius: 99,
    overflow: 'hidden',
    backgroundColor: 'white',
    justifyContent: 'center',
    alignItems: 'center',
    border: 'solid #1a1a1a 1px',
    marginBottom: 5,

  },
  tabs: {
    transition: 'all 0.4s ease',
    borderRadius: 99,
    overflow: 'hidden',
    minWidth: 60,
    maxWidth: 60,
  },
  section: {

    paddingTop: 120,
    paddingBottom: 120,
  },
  wrapper: {
    maxWidth: '100%',
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    textAlign: 'center',
    alignItems: 'center',

  },
  progress: {
    margin: 5,
  },
}

function ScaleSwitcher(props) {

  const handleChangeH = (event, value) => {
    // setValues(oldValues => ({
    //   ...oldValues,
    //   [event.target.name]: event.target.value,
    // }))

    client.writeData(
      {
        data: { activeScaleSizecharts: value },
      },
    )
  }

  const { classes } = props
  const { loading, error, data } = useQuery(CACHE_STATE)

  if (loading) return 'Loading...'
  if (error) return `Error! ${error.message}`
  if (data) return (
    <div className={classes.root}>

      <Tabs
        value={data.activeScaleSizecharts}
        indicatorColor="#1a1a1a"
        textColor="#1a1a1a"
        onChange={(event, value) => handleChangeH(event, value)}
        aria-label="disabled tabs example"
      >
        <Tab label="CM" className={classes.tabs}
             value={'CM'}
             style={data.activeScaleSizecharts === 'CM' ? {
               backgroundColor: '#1a1a1a',
               color: 'white',
             } : {
               color: '#1a1a1a',
             }}/>

        <Tab label="INCH" className={classes.tabs}
             value={'INCH'}
             style={data.activeScaleSizecharts === 'INCH' ? {
               backgroundColor: '#1a1a1a',
               color: 'white',
             } : {
               color: '#1a1a1a',
             }}/>
      </Tabs>
    </div>
  )
}

export default withStyles(styles)(ScaleSwitcher)