import React from 'react'
import withStyles from '@material-ui/core/styles/withStyles'
import Button from 'components/CustomButtons/Button.jsx'
// import Button from '@material-ui/core/Button';
import PropTypes from 'prop-types'


const styles = {
    root: {
        display: 'flex',
        height: "100%",
        width: '100%',
        justifyContent: 'center',
        alignItems: 'center',
    },
    wrapper: {
        display: 'flex',
        maxWidth: '100%',
        width: '100%',
        maxHeight: 70,
        margin: 0,
    },
}


const SearchButtonFullWidth = props => {

    const {classes} = props
    return (

        <Button
            color="primary"
            size="lg"
            root={{height: "100%"}}
            onClick={props.onClick}
            className={classes.wrapper}
            style={props.innerStyles}
            fullWidth
        >
            {props.searchButtonText}
        </Button>


    )
}
SearchButtonFullWidth.defaultProps = {
    innerStyles: {},
    rootStyles: {},

}
SearchButtonFullWidth.propTypes = {
    innerStyles: PropTypes.object,
    rootStyles: PropTypes.object,

}
export default withStyles(styles)(SearchButtonFullWidth)
