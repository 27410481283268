import React from 'react'
import { client } from '../../apollo/client'
import { CACHE_STATE } from '../../apollo/gql/CACHE_STATE'
import TriggerSearch from './TriggerSearch'


// Sections for this page

const stdAmount = 12

const TriggerSearchMore = async () => {


  await client.writeData(
    {
      data: { loadingMore: true },
    },
  )

  const cache = await client.query({
    query: CACHE_STATE,
  })

  const getLatest = cache.data.productAmount + stdAmount

  console.log('NEWcache', cache)
  console.log('getLatest', getLatest)

  await client.writeData(
    {
      data: {
        productAmount: getLatest,
      },
    },
  )

  await TriggerSearch()

  await client.writeData(
    {
      data: {
        loadingMore: false,
      },
    },
  )


}

export default TriggerSearchMore