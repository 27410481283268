import React from 'react'
// import BlogSection from '../../elements/RecentBlogPosts/RecentBlogPosts'
import withStyles from '@material-ui/core/styles/withStyles'
// Sections for this page
import IconUnhappy from '@material-ui/icons/EmojiPeople'
import {CACHE_RESULTS} from '../../../../apollo/gql/CACHE_RESULTS'
import {useQuery} from '@apollo/react-hooks'
import {NothingFoundStyle} from "./nothingFoundStyle";

function NothingFound(props) {

    const {classes, actLang} = props

    const {loading, error, data} = useQuery(CACHE_RESULTS)

    if (loading) return 'Loading...'
    if (error) return `Error! ${error.message}`

    // console.log('CACHE_RESULTS', data)

    // return <h1>    {JSON.stringify(data)}</h1>
    //
    return (
        <div className={classes.root}>
            {/*{JSON.stringify(sizes)}*/}
            {
                actLang === 'de' ?
                    <div className={classes.noResultswrapper}>
                        <IconUnhappy fontSize={'large'}/>
                        <p className={classes.noResultswrapperFont}>Nichts gefunden - Versuche leicht abweichende Maße
                            einzugeben!</p>
                        <p className={classes.noResultswrapperFont}>
                            ↝ Unsere Suchmaschine ist sehr
                            genau und zeigt wirklich nur Produkte
                            an die perfekt passen</p>
                    </div> :
                    <div className={classes.noResultswrapper}>
                        <IconUnhappy fontSize={'large'}/>
                        <p className={classes.noResultswrapperFont}>No results - Try to enter slightly different
                            dimensions!</p>
                        <p className={classes.noResultswrapperFont}>↝ Our search engine is very
                            accurate and really only shows products that fit perfectly. </p>
                    </div>
            }
        </div>

    )

}

// export default WetsuitResults
export default withStyles(NothingFoundStyle)(NothingFound)
