import React from 'react'

const searchBarLedgeStyle = {

    subWrapper: {

        width: "100%",
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
        '@media (max-width: 1020px)': {
            flexDirection: 'column',
            alignItems: 'center',
        },
    },
    hideline:{
        display: 'inherit',
        '@media (max-width: 670px)': {
            display: 'none',
        },
    },
    line:{
        display: "flex",
        width: 1,
        marginRight: 10,
        marginLeft: 10,
        height: 50,
        borderRight: "solid red 0.5px",
    },
    buttonWrapper: {
        display: "flex",
        height: "100%",
        justifyContent: "center",
        alignItems: "center",

        flex: 1,
        maxWidth: 150,
        marginLeft: 15,

        '@media (max-width: 1020px)': {
            margin: 0,
            marginTop: 20,
            width: "100%",
            maxWidth: 520,
        },

    },
    dimDesktop: {
        display: 'inherit',
        '@media (max-width: 768px)': {
            display: 'none',
        },
    },
    dimMobile: {
        padding: 5,
        paddingBottom: 10,
        paddingTop: 10,
        paddingLeft: 15,
        paddingRight: 15,
        flexDirection: 'row',
        height: "100%",
        display: 'none',
        '@media (max-width: 768px)': {
            display: 'inherit',
        },
    },
    paper: {
        margin: 0,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: "center",
        alignItems: "center",
        '@media (max-width: 670px)': {
            flexDirection: 'column',
            width: "100%",
        },
    },
    listContainer: {
        padding: 5,
        paddingBottom: 10,
        paddingTop: 10,
        paddingLeft: 15,
        paddingRight: 15,
        display: 'flex',
        flexDirection: 'row',
        height: "100%",

    },
    container: {
        width: '100%',
        zIndex: '2',
        position: 'relative',
        color: '#FFFFFF',
    },
    cardHidden: {
        opacity: '0',
        transform: 'translate3d(0, -60px, 0)',
    },
    dropdownListWrapper: {
        flexDirection: 'column', display: 'flex', flex: 1, marginTop: 10, marginBottom: 10,
    },
    dropdownListItem: {
        flexGrow: 1,
        padding: 5,
        backgroundColor: '#f9f9f9',
        marginLeft: 0,
        marginRight: 0,
        paddingLeft: 10,
        marginBottom: 15,
    },
    dropdownListWrapperRow: {
        flexDirection: 'row',
        display: 'flex',
        width: '100%',
        maxWidth: '100%',
        flexWrap: 'wrap',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        marginTop: 10,
        marginBottom: 10,
    },
    dropdownListItemRow: {
        flexGrow: 1,
        padding: 5,
        backgroundColor: '#f9f9f9',
        margin: 8,
        marginBottom: 4,
        marginLeft: 0,
    },
    pageHeader: {
        minHeight: '100vh',
        height: 'auto',
        display: 'inherit',
        position: 'relative',
        margin: '0',
        padding: '0',
        border: '0',
        alignItems: 'center',
        '&:before': {
            background: 'rgba(0, 0, 0, 0.5)',
        },
        '&:before,&:after': {
            position: 'absolute',
            zIndex: '1',
            width: '100%',
            height: '100%',
            display: 'block',
            left: '0',
            top: '0',
            content: '""',
        },
        '& footer li a,& footer li a:hover,& footer li a:active': {
            color: '#FFFFFF',
        },
        '& footer': {
            position: 'absolute',
            bottom: '0',
            width: '100%',
        },
    },
    form: {
        margin: '0',
    },
    cardHeader: {
        width: 'auto',
        textAlign: 'center',
        marginLeft: '20px',
        marginRight: '20px',
        marginTop: '-40px',
        padding: '20px 0',
        marginBottom: '15px',
    },
    socialIcons: {
        maxWidth: '24px',
        marginTop: '0',
        width: '100%',
        transform: 'none',
        left: '0',
        top: '0',
        height: '100%',
        lineHeight: '41px',
        fontSize: '20px',
    },
    divider: {
        marginTop: '30px',
        marginBottom: '0px',
        textAlign: 'center',
    },
    cardFooter: {
        paddingTop: '0rem',
        border: '0',
        borderRadius: '6px',
        justifyContent: 'center !important',
    },
    socialLine: {
        marginTop: '1rem',
        textAlign: 'center',
        padding: '0',
    },
    inputIconsColor: {
        color: '#495057',
    },
    humanTypeValuePrev: {
        display: 'flex',
        flexDirection: 'row',
        height: '100%',
        justifyContent: 'center',
        alignItems: 'center',
    },
}

export default searchBarLedgeStyle
