import React from 'react'
import {makeStyles} from '@material-ui/core/styles'
import {useQuery} from '@apollo/react-hooks'
import {CACHE_STATE} from '../../apollo/gql/CACHE_STATE'
import LoadMoreHook from './LoadMoreHook'
import SearchButtonFullWidth from '../../components/SearchButtonFullWidth/SearchButtonFullWidth'
import TriggerSearchMore from './TriggerSearchMore'

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    formControl: {
        margin: 5,
        minWidth: 120,
    },
    selectEmpty: {
        marginTop: 5,
    },
}))

export default function HasNextPage(props) {
    const classes = useStyles()


    const {loading, error, data} = useQuery(CACHE_STATE)

    return (
        <>
            {data.hasNextPage ?
                <LoadMoreHook>
                    <SearchButtonFullWidth
                        innerStyles={{maxWidth: '60%', minWidth: 150, marginTop: 20}}
                        searchButtonText={props.searchButtonText}
                        onClick={() => {
                            TriggerSearchMore()
                        }}/>
                </LoadMoreHook> : null}
        </>

    )
}
