import gql from 'graphql-tag'

export const CACHE_STATE = gql`
    query Variables {
        humanType @client
        
        activeScaleSizecharts @client

        heightFeet @client
        heightInch @client
        height @client
        weight @client
        chest @client
        waist @client
        hips  @client
        neck  @client
        priceMin  @client
        priceMax  @client
        
        orderBy  @client

        frontzip  @client
        backzip  @client
        nozip  @client

        allTemperatures @client
        tropics @client
        summer @client
        autumn @client
        winter @client

        mystic @client
        orca @client
        billabong @client
        hurley @client
        ion @client
        neilpryde @client
        oneill @client
        quiksilver @client
        ripCurl @client
        roxy @client
        vissla @client
        xcel @client
        aquaSphere @client
        arena @client
        patagonia @client

        cursor_current_end @client
        currentPageHastNextPage @client
        
        activeScale @client
        activeAfilCountry @client

        productAmount @client
        hasNextPage @client

       
    }
`