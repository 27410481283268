const valuePreviewStyle = {
  root: {
    display: 'flex',
    flexDirection: 'row',
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  item: {
    marginRight: 5,
    color: 'gray',
  },
}

export default valuePreviewStyle
