import React from 'react'
// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles'
// @material-ui/icons

const styles = {
  root: {},
}

const Divider = props => {
  const { classes } = props
  return (
    <div style={{
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      marginTop: 10,
      marginBottom: 10,
    }}>
      <div style={{
        height: 1,
        backgroundColor: '#f2f2f2',
        width: '150%',
      }}/>
    </div>
  )

}

export default withStyles(styles)(Divider)
