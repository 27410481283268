import React from 'react'
import withStyles from '@material-ui/core/styles/withStyles'
import brandSizeChartCellStyle from './brandSizeChartCellStyle'
import TableCell from '@material-ui/core/TableCell'

const styles = {
  root: {},
  tabs: {
    transition: 'all 0.4s ease',
  },
}

const BrandSizeChartCell = props => {

  const { classes, firstVal, secondVal } = props

  let getString = ''


  if (firstVal && firstVal !== 0 && secondVal && secondVal !== 0) {
    getString = firstVal + ' - ' + secondVal
  } else if (firstVal && firstVal !== 0 && !secondVal) {
    if (secondVal === 0 && !secondVal)
      getString = firstVal
  } else if (!firstVal) {
    getString = ''
  } else {
    getString = ''
  }

  return (

    <TableCell
      className={classes.bodyCell}
      align="center"
    >
      {getString}
    </TableCell>

  )

}

export default withStyles(brandSizeChartCellStyle)(BrandSizeChartCell)

