import React from 'react'
import withStyles from '@material-ui/core/styles/withStyles'
import sizeChartTableStyle from './SizeChartTableStyle'
import Paper from '@material-ui/core/Paper'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import BrandSizeChartCell from './BrandSizeChartCell'
import Img from 'gatsby-image'
import ScaleSwitcher from './ScaleSwitcher'

const SizeChartTable = props => {

  const columns = [
    { id: 'size', label: 'Size', align: 'center' },
    { id: 'height', label: 'Height', align: 'center' },
    { id: 'weight', label: 'Weight', align: 'center' },
    { id: 'chest', label: 'Chest', align: 'center' },
    { id: 'waist', label: 'Waist', align: 'center' },
    { id: 'hips', label: 'Hips', align: 'center' },
    { id: 'neck', label: 'Neck', align: 'center' },
  ]
  console.log('props', props.chartSizes)
  const { classes, chartSizes, activeScale, ht, logo, brandname, actLang,onlyChart } = props

  if (chartSizes.length > 0) {
    return (
      <div className={classes.root}>

        {!onlyChart ? (<div style={{
          width: '100%',
          display: 'flex',
          justifyContent: 'flex-start',
          alignItems: 'center',
          flexDirection: 'row',
        }}>

          <div className={classes.imgWrapper}>
            <Img
              className={classes.img}
              fluid={logo}
              objectPosition="50% 50%"
              backgroundColor={'#f2f2f2'}
              alt={''}
            /></div>

          <h3 style={{
            margin: 0,
            padding: 0,
            paddingLeft: 15,
            fontSize: 25,
          }}>{brandname} - {
            ht === 'MEN' ? actLang === 'de' ? 'Männer' : 'Men' :
              ht === 'WOMEN' ? actLang === 'de' ? 'Frauen' : 'Women' :
                ht === 'KIDS' ? 'Kids' : null

          }</h3>
          <div style={{
            flexGrow: 1,
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
            flexDirection: 'row',
          }}><ScaleSwitcher/></div>

        </div>) : (null)}

        <Paper className={classes.paper}>
          <div className={classes.tableWrapper}>


            <Table stickyHeader={true}>
              <TableHead>

                <TableRow>
                  {columns.map(column => (
                    <TableCell
                      className={classes.head}
                      key={column.id}
                      align={column.align}
                      // style={{ minWidth: column.minWidth }}
                    >
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>

                {chartSizes.map(size => {

                  let heightMin = size.heightMin
                  if (activeScale === 'INCH') {
                    const feet = Math.floor(size.heightMinInch / 12)
                    const rest = size.heightMinInch - (feet * 12)
                    heightMin = `${feet} '' ${rest}'`
                  }

                  let heightMax = size.heightMax
                  if (activeScale === 'INCH') {
                    const feet = Math.floor(size.heightMaxInch / 12)
                    const rest = size.heightMaxInch - (feet * 12)
                    heightMax = `${feet} '' ${rest}'`
                  }
                  // let heightMax = activeScale === 'CM' ?
                  //   size.heightMax :
                  //   size.heightMaxInch !== 0 ?
                  //     `${Math.round(size.heightMaxInch / 12)}'' ${(size.heightMaxInch - Math.round(size.heightMaxInch / 12) * 12)}'` : size.heightMaxInch

                  //
                  // const heightMin = activeScale === 'CM' ? size.heightMin : size.heightMinInch
                  // const heightMax = activeScale === 'CM' ? size.heightMax : size.heightMaxInch
                  const weightMin = activeScale === 'CM' ? size.weightMin : size.weightMinInch
                  const weightMax = activeScale === 'CM' ? size.weightMax : size.weightMaxInch
                  const chestMin = activeScale === 'CM' ? size.chestMin : size.chestMinInch
                  const chestMax = activeScale === 'CM' ? size.chestMax : size.chestMaxInch
                  const waistMin = activeScale === 'CM' ? size.waistMin : size.waistMinInch
                  const waistMax = activeScale === 'CM' ? size.waistMax : size.waistMaxInch
                  const hipsMin = activeScale === 'CM' ? size.hipsMin : size.hipsMinInch
                  const hipsMax = activeScale === 'CM' ? size.hipsMax : size.hipsMaxInch
                  const neck = activeScale === 'CM' ? size.neck : size.neckInch
                  const name = activeScale === 'CM' ?
                    size.name :
                    size.nameUS ? size.nameUS : size.name

                  return (
                    <TableRow hover key={size.name} className={classes.mainRow}>
                      <TableCell component="th" scope="row"
                        // align={column.align}
                                 className={classes.bodyCell}
                                 align="center"
                      >
                        {name}
                      </TableCell>

                      <BrandSizeChartCell
                        firstVal={heightMin}
                        secondVal={heightMax}
                      />
                      <BrandSizeChartCell
                        firstVal={weightMin}
                        secondVal={weightMax}
                      />
                      <BrandSizeChartCell
                        firstVal={chestMin}
                        secondVal={chestMax}
                      />
                      <BrandSizeChartCell
                        firstVal={waistMin}
                        secondVal={waistMax}
                      />
                      <BrandSizeChartCell
                        firstVal={hipsMin}
                        secondVal={hipsMax}
                      />
                      <BrandSizeChartCell
                        firstVal={neck}
                        secondVal={null}
                      />
                    </TableRow>
                  )
                })}
              </TableBody>
            </Table>
          </div>
        </Paper>
      </div>


    )
  } else {
    return null
  }

}
SizeChartTable.defaultProps = {
  onlyChart: false,
}

export default withStyles(sizeChartTableStyle)(SizeChartTable)
