import React from 'react'
import withStyles from '@material-ui/core/styles/withStyles'
import valuePreviewStyle from './valuePreviewStyle'
import ListItemText from '@material-ui/core/ListItemText'
import PropTypes from 'prop-types'


const ValuePreview = props => {


  const { classes } = props


  return (
    <div className={classes.root}>
      {props.values  ? props.values.map(item => {
        return (
          item ? <ListItemText className={classes.item}>
           {item.title}
          </ListItemText> : null
        )
      }) : null}


      {props.children}
    </div>
  )

}
ValuePreview.defaultProps = {
  price: false,
}

ValuePreview.propTypes = {
  price: PropTypes.bool

}
export default withStyles(valuePreviewStyle)(ValuePreview)
