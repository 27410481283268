import gql from 'graphql-tag'


export const FULL_MATCH_FRAGMENT = gql`
    fragment wetsuitInfo on wetsuitsConnection {
        pageInfo{startCursor endCursor hasNextPage }
        
        edges{
            cursor
            node{
                size{
                    name
                }

                brand{name}
                updatedAt
                productName
                price
                frontzip
                backzip
                zipless
            }
        }
    }
`;