import gql from 'graphql-tag'

export const CACHE_RESULTS = gql`
    query Results {
        results @client{
            aggregate { count}
            pageInfo { startCursor endCursor hasNextPage }
            edges {
                cursor
                node{
                    id
                    size{
                        name
                    }
                    brand{
                        name
                        displayName
                    }
                    updatedAt
                    productName
                    price
                    frontzip
                    backzip
                    zipless
                    afilLink
                    bigImage
                    smallImage
                    reducedPrice
                    oldPrice
                    currency
                    afilPartner
                }
            }
        }
    }
`