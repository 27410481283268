import React from 'react'
// nodejs library that concatenates classes
// nodejs library to set properties for components
import PropTypes from 'prop-types'
// material-ui components
import withStyles from '@material-ui/core/styles/withStyles'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'

import customTabsStyle from 'assets/jss/material-kit-react/components/customTabsStyle.jsx'
import CellMinMax from './CellMinMax'
import CellSingle from './CellSingle'
import { Grid } from '@material-ui/core'

function createData(name, calories, fat, carbs, protein) {
  return { name, calories, fat, carbs, protein }
}

const rows = [
  createData('Frozen yoghurt', 159, 6.0, 24, 4.0),
  createData('Ice cream sandwich', 237, 9.0, 37, 4.3),
  createData('Eclair', 262, 16.0, 24, 6.0),
  createData('Cupcake', 305, 3.7, 67, 4.3),
  createData('Gingerbread', 356, 16.0, 49, 3.9),
]

class SizeTable extends React.Component {
  state = {
    value: 0,
  }

  handleChange = (event, value) => {
    this.setState({ value })
  }

  render() {
    const {
      classes,
      headerColor,
      plainTabs,
      tabs,
      title,
      rtlActive,
      inch,
    } = this.props


    return (
      <Grid item xs={12}>
        <Table size="small" >
          <TableHead>
            <TableRow>
              <TableCell>Size</TableCell>
              <TableCell align="right">Height</TableCell>
              <TableCell align="right">Weight</TableCell>
              <TableCell align="right">Chest</TableCell>
              <TableCell align="right">Waist</TableCell>
              <TableCell align="right">Hips</TableCell>
              <TableCell align="right">Neck</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {this.props.sizes.map(size => {
                const name = size.name
                const heightMin = inch ? size.heightMinInch : size.heightMin
                const heightMax = inch ? size.heightMaxInch : size.heightMax

                const weightMin = inch ? size.weightMinInch : size.weightMin
                const weightMax = inch ? size.weightMaxInch : size.weightMax

                const chestMin = inch ? size.chestMinInch : size.chestMin
                const chestMax = inch ? size.chestMaxInch : size.chestMax

                const waistMin = inch ? size.waistMinInch : size.waistMin
                const waistMax = inch ? size.waistMaxInch : size.waistMax

                const hipsMin = inch ? size.hipsMinInch : size.hipsMin
                const hipsMax = inch ? size.hipsMaxInch : size.hipsMax

                const neck = inch ? size.neck : size.neck

                return (
                  <TableRow key={name}>

                    <CellSingle bold val={name}/>
                    <CellMinMax min={heightMin} max={heightMax}/>
                    <CellMinMax min={weightMin} max={weightMax}/>
                    <CellMinMax min={chestMin} max={chestMax}/>

                    <CellMinMax min={waistMin} max={waistMax}/>
                    <CellMinMax min={hipsMin} max={hipsMax}/>
                    <CellSingle val={neck}/>
                  </TableRow>
                )
              },
            )}
          </TableBody>
        </Table>
      </Grid>

    )
  }
}

CellSingle.defaultProps = {
  inch: false,
}
SizeTable.propTypes = {
  classes: PropTypes.object.isRequired,
  headerColor: PropTypes.oneOf([
    'warning',
    'success',
    'danger',
    'info',
    'primary',
    'rose',
  ]),
  title: PropTypes.string,
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      tabName: PropTypes.string.isRequired,
      tabIcon: PropTypes.func,
      tabContent: PropTypes.node.isRequired,
    }),
  ),
  rtlActive: PropTypes.bool,
  plainTabs: PropTypes.bool,
}

export default withStyles(customTabsStyle)(SizeTable)
