import React from 'react'
// import BlogSection from '../../elements/RecentBlogPosts/RecentBlogPosts'
// Sections for this page
import { useQuery } from '@apollo/react-hooks'
import withStyles from '@material-ui/core/styles/withStyles'
// import { SIZES } from './HeightWeight'
import { CACHE_STATE_LOADING } from '../../apollo/gql/CACHE_STATE_LOADING'

const styles = {
  root: {
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
  },
  section: {

    paddingTop: 120,
    paddingBottom: 120,
  },
  wrapper: {
    maxWidth: '100%',
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    textAlign: 'center',
    alignItems: 'center',

  },
  progress: {
    marginTop: 20,
    margin: 5,
  },
}

function LoadMoreHook(props) {
  const { classes, children } = props
  const { loading, error, data } = useQuery(CACHE_STATE_LOADING)

  if (loading) return 'Loading...'
  if (error) return `Error! ${error.message}`
  if (data) return (
    <div className={classes.root}>
      {data ? data.loadingMore ? null :
        <>
          {children}
        </> : null}

    </div>
  )
}

export default withStyles(styles)(LoadMoreHook)