import React from 'react'
// @material-ui/core components
import ScaleChooserHook from '../SearchComponents/ScaleChooserHook'
import {Paper, withStyles} from '@material-ui/core'
import PropTypes from 'prop-types'
import searchBarLedgeStyle from './searchBarLedgeStyle'
import DimensionsHeightWidth from '../SearchComponents/Dimensions/DimensionsHeightWidth'
import HumantypeChooserHook from '../SearchComponents/HumantypeChooserHook'
import SearchButtonFullWidth from "../../components/SearchButtonFullWidth/SearchButtonFullWidth";
import Section from "../../components/Section/Section";
import {client} from "../../apollo/client";
import {CACHE_STATE} from "../../apollo/gql/CACHE_STATE";
import SeperatorLine from "../../components/SeperatorLine/SeperatorLine";

const SearchBarLedge = (props) => {

    const trigger = async () => {
        const cache = await client.query({
            query: CACHE_STATE,
        })

        if (
            cache.data.heightFeet ||
            cache.data.heightInch ||
            cache.data.height ||
            cache.data.weight
        ) {
            if (props.actLang === 'de') {
                client.writeData(
                    {
                        data: {
                            activeAfilCountry: 'DE',

                        },
                    },
                )
            }
            if (props.actLang === 'en') {
                client.writeData(
                    {
                        data: {
                            activeAfilCountry: 'UK',
                        },
                    },
                )
            }

        } else {
            if (props.actLang === 'de') {
                client.writeData(
                    {
                        data: {
                            activeAfilCountry: 'DE',
                            activeScale: 'CM',
                        },
                    },
                )
            }
            if (props.actLang === 'en') {
                client.writeData(
                    {
                        data: {
                            activeAfilCountry: 'UK',
                            activeScale: 'INCH',
                        },
                    },
                )
            }
        }

    }

    trigger()


    const {classes, actLang, transl} = props
    return (
        <Section paddingTop={0} paddingBottom={0} backgroundColor={"transparent"} flexDirRow={true}
                 innerStyles={{width: "100%", display: "flex", justifyContent: "center", alignItems: "center"}}>

            <Paper className={classes.paper}>

                <div className={classes.listContainer}>
                    <ScaleChooserHook
                        title={'Scale'}
                    />

                    {/*<div></div>*/}
                    <SeperatorLine/>
                    {/*<HumanType*/}
                    {/*  title={'Gender'}*/}
                    {/*  searchSectionGenderLabelMen={'Men'}*/}
                    {/*  searchSectionGenderLabelWomen={'Women'}*/}
                    {/*  searchSectionGenderLabelKids={'Kids'}*/}
                    {/*/>*/}
                    <HumantypeChooserHook transl={transl}/>
                    <SeperatorLine/>

                    <div className={classes.dimDesktop}>
                        <DimensionsHeightWidth transl={transl}/>
                    </div>

                </div>
                <div className={classes.dimMobile}>
                    <DimensionsHeightWidth transl={transl}/>
                </div>
            </Paper>

            <div className={classes.buttonWrapper}>
                <SearchButtonFullWidth
                    onClick={props.onSBClick}
                    searchButtonText={transl.searchButtonTitle}
                />
            </div>

        </Section>

    )

}

SearchBarLedge.defaultProps = {
    searchSizesOnly: false,
    showButton: true,
}
SearchBarLedge.propTypes = {
    searchSizesOnly: PropTypes.bool,
    showButton: PropTypes.bool,

}
export default withStyles(searchBarLedgeStyle)(SearchBarLedge)
