import React from 'react'

import withStyles from '@material-ui/core/styles/withStyles'
import SearchBarWetsuitFinder from './SearchBarWetsuitFinder'
import FullScreenDialog from './FullScreenDialog/FullScreenDialog'


// import BlogSection from '../../elements/RecentBlogPosts/RecentBlogPosts'

// Sections for this page
const style = {
  root: {},
  onDesktop: {

    '@media (max-width: 768px)': {
      display: 'none',
    },
  },
  onMobile: {
    width: '100%',
    display: 'none',
    '@media (max-width: 768px)': {
      display: 'inherit',
    },
  },
}

const SearchBarProvider = props => {

  const { classes, searchSizesOnly,transl } = props

  return (
    <div style={{width:"100%"}}>
      <div className={classes.onDesktop}>
        {/*<SearchButtonFullWidth */}
        {/*                       searchButtonText={labels.searchButtonText}*/}
        {/*/>*/}
        <SearchBarWetsuitFinder
          searchSizesOnly={searchSizesOnly}
          transl={transl}
        />
      </div>
      <div className={classes.onMobile}>
        <FullScreenDialog
          transl={transl}
          onSBClick={props.onSBClick}
          searchSizesOnly={searchSizesOnly}
          showButton={false}
        />
      </div>
    </div>


  )

}

export default withStyles(style)(SearchBarProvider)
