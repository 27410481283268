const buttonScrollDownStyle = {

  root: {
    height: 60,
    width: 60,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 99,
    backgroundColor: 'transparent',
    border: '1px solid #fff',
    color: 'white',
    cursor: 'pointer',
    transition: 'all 0.5s ease',
    '&:hover': {
      opacity: 0.7,


    },

    '@media (max-width: 376px)': {
      height: 30,
      width: 30,
    },

  },
  down: {

    fontFamily: 'sans-serif',
    textAlign: 'center',
  },
}

export default buttonScrollDownStyle
