import React from 'react'
// nodejs library to set properties for components
import PropTypes from 'prop-types'
// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles'

import finderPageStyle from './finderPageStyle'

function MainRight({ ...props }) {
  const { classes, color, children } = props
  return (
    <span className={classes.mainRightRoot}>{children}</span>
  )
}

MainRight.defaultProps = {
  color: 'gray',
}

MainRight.propTypes = {
  classes: PropTypes.object.isRequired,

}

export default withStyles(finderPageStyle)(MainRight)
