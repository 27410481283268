import React from 'react'
// import BlogSection from '../../elements/RecentBlogPosts/RecentBlogPosts'
// Sections for this page
import { useQuery } from '@apollo/react-hooks'
import withStyles from '@material-ui/core/styles/withStyles'
// import { SIZES } from './HeightWeight'
import { CACHE_STATE_LOADING } from '../../apollo/gql/CACHE_STATE_LOADING'
import CircularProgress from '@material-ui/core/CircularProgress'
import DisplayWetsuitsRaw from './Display/WetsuitResults'
import LoadMoreSpinner from './LoadMoreSpinner'

const styles = {
  section: {

    paddingTop: 120,
    paddingBottom: 120,
  },
  wrapper: {
    maxWidth: '100%',
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    textAlign: 'center',
    alignItems: 'center',

  },
  progress: {
    margin: 5,
  },
}

function Loader(props) {

  const { classes } = props
  const { loading, error, data } = useQuery(CACHE_STATE_LOADING)

  if (loading) return 'Loading...'
  if (error) return `Error! ${error.message}`
  if (data) return (
    <div>
      {data ? data.loading ? <CircularProgress className={classes.progress} color="primary"/> :
        null : null}
    </div>
  )

}

export default withStyles(styles)(Loader)