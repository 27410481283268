import React from 'react'
import withStyles from '@material-ui/core/styles/withStyles'
import TableCell from '@material-ui/core/TableCell'


const styles = {
  root: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  wrapper: {
    width: '100%',
    display: 'flex',
    flex: 1,
    maxWidth: '100%',
    height: 60,
    marginBottom: 15,
  },
}


const CellMinMax = props => {

  const { classes, min, max } = props
  return (
    <TableCell align="center">
      {min ?
        min : null
      }
      {min && max ? ' - ' : null}
      {max ?
        max : null
      }
    </TableCell>

  )
}

CellMinMax.defaultProps = {
  min: 0,
  max: 0,


}

export default withStyles(styles)(CellMinMax)
