import React from 'react'
// nodejs library that concatenates classes
// nodejs library to set properties for components
// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles'
// core components
import Img from 'gatsby-image'
import Section from '../Section/Section'
import pageHeaderFinderStyle from './pageHeaderFinderStyle'
import SearchBarLedge from '../../elements/SearchBarLedge/SearchBarLedge'

const PageHeaderFinder = props => {


    const {classes} = props
    const {

        alt,
        image,
        title,
        subtitle,
    } = props

    return (
        <div className={classes.root}>
            <Img
                // className={imageClasses}
                style={{
                    height: '100%',
                    width: '100%',
                    filter: 'saturate(80%) brightness(80%) contrast(110%)',
                }}
                alt={alt ? alt : 'Perfect Wetsuit'}
                fluid={image}
                backgroundColor={'#f2f2f2'}
                objectFit="contain"
            />

            <div className={classes.container}>
                <Section backgroundColor={'transparent'} paddingBottom={0} paddingTop={0} innerStyles={{maxWidth: 680}}>
                    {title ?
                        <div>
                            <h1 className={classes.headerTitle}>
                                {title}
                            </h1>
                            <div style={{height: 15}}/>
                            {/*<h2 className={classes.headerSubTitle}>*/}
                            {/*    {subtitle}*/}
                            {/*</h2>*/}
                        </div>
                        :
                        <div>
                            {/*<h2 className={classes.headerTitle}>*/}
                            {/*    {subtitle}*/}
                            {/*</h2>*/}
                        </div>
                    }
                </Section>
                <div style={{height: 20, width: "100%"}}/>
                <SearchBarLedge
                    onSBClick={props.onSBClick}
                    transl={props.transl}
                    actLang={props.actLang}/>
            </div>

            {/*<div className={classes.scrollDown}>*/}

            {/*  <ButtonScrollDown/>*/}
            {/*</div>*/}
        </div>
    )

}


// PageHeader.propTypes = {
//   classes: PropTypes.object.isRequired,
//   className: PropTypes.string,
//   filter: PropTypes.bool,
//   children: PropTypes.node,
//   style: PropTypes.string,
//   image: PropTypes.string,
// }

export default withStyles(pageHeaderFinderStyle)(PageHeaderFinder)
