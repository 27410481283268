import React from 'react'
// nodejs library to set properties for components
// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles'

const styles = {
    hideline: {
        
        '@media (max-width: 670px)': {
            width: 1,
            marginRight: 10,
            marginLeft: 10,
        },
    },
    line: {

        width: 1,
        marginRight: 10,
        marginLeft: 10,
        height: 50,
        borderRight: "solid #f2f2f2 0.5px",
        display: 'inherit',
        '@media (max-width: 670px)': {
            display: 'none',
        },
    },
}

const SeperatorLine = (props) => {
    const {classes, color, children} = props
    return (
        <div className={classes.hideline}>
            <div className={classes.line}/>
        </div>
    )
}


export default withStyles(styles)(SeperatorLine)
