import React from 'react'
import withStyles from '@material-ui/core/styles/withStyles'
import searchBarStyle from '../../SearchBar/searchBarStyle'
import {client} from '../../../apollo/client'
import {CACHE_STATE} from '../../../apollo/gql/CACHE_STATE'
import SearchBarInputCustom from "../../../components/SearchBarInputCustom/SearchBarInputCustom";


class DimensionElementSmall extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            [props.stateProp]: '',
        }
    }

    async componentDidMount() {
        // this.triggerQuery()
        const cache = await client.query({
            query: CACHE_STATE,
        })
        const data = cache.data[this.props.stateProp]
        this.setState({
            [this.props.stateProp]: data,
        })
    }

    handleChangeInStateForm = prop => event => {

        this.setState({
            [prop]: event.target.value,
        })

        client.writeData(
            {
                data: {[prop]: event.target.value},
            },
        )

        console.log(prop, event.target.value)

    }

    render() {

        const {classes, stateProp, labelText, placeholder} = this.props


        return (
            <div style={{
                width: '100%',
                height: "100%",
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',

                marginLeft: 0,
                marginRight: 0,

                paddingTop: 0

            }}>
                <SearchBarInputCustom
                    small={this.props.small}
                    inputAdorm={this.props.inputAdorm}
                    labelText={labelText}
                    id={stateProp}
                    type="text"
                    outlined
                    inputProps={{
                        type: 'text',
                        value: this.state[stateProp],
                        onChange: this.handleChangeInStateForm(stateProp),
                    }}
                    placeholder={this.props.placeholder}
                />
            </div>


        )
    }
}

export default withStyles(searchBarStyle)(DimensionElementSmall)
