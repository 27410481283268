import React from 'react'
import {useQuery} from '@apollo/react-hooks'
import {CACHE_STATE} from '../../../apollo/gql/CACHE_STATE'
import DimensionElementSmall from './DimensionElementSmall'
import SeperatorLine from "../../../components/SeperatorLine/SeperatorLine";


export default function DimensionsHeightWidth(props) {

    const [values, setValues] = React.useState({
        open: false,
    })

    const {loading, error, data} = useQuery(CACHE_STATE)
    const {
        transl,

    } = props

    return (
        <div style={{
            margin: 0,
            padding: 0,
            display: 'flex',
            flexDirection: 'row',
            justifyContent: "center",
            alignItems: "center",
            height: "100%"
        }}>
            {data ?
                data.activeScale === 'CM' ?

                    <div style={{display: 'flex', flexDirection: 'row', flexWrap: "wrap"}}>
                        <div>
                            <DimensionElementSmall stateProp={'height'}
                                                   labelText={transl.searchSectionDimensionsHeight}
                                                   placeholder={'cm'}/>
                        </div>
                        <SeperatorLine/>
                        <div>
                            <DimensionElementSmall stateProp={'weight'}
                                                   labelText={transl.searchSectionDimensionsWeight}
                                                   placeholder={'kg'}/>
                        </div>
                    </div>

                    :

                    <div style={{display: 'flex', flexDirection: 'row'}}>

                        <DimensionElementSmall stateProp={'heightFeet'}
                                               labelText={transl.searchSectionDimensionsHeight}
                                               placeholder={'feet'}
                                               small={true}
                        />
                        <DimensionElementSmall stateProp={'heightInch'}
                                               labelText={""}
                                               small={true}
                                               placeholder={'inch'}/>
                        <SeperatorLine/>
                        <DimensionElementSmall stateProp={'weight'} labelText={transl.searchSectionDimensionsWeight}
                                               placeholder={'lbs'}/>


                    </div>


                :
                null
            }

        </div>
    )

}


