const sizeChartTableStyle = {
  root: {
    width: '100%',
    marginTop: 20,
  },
  head: {
    backgroundColor: '#1a1a1a',
    position: 'sticky',
    top: 0,
    color: 'white',

  },
  bodyCell: {
    // minWidth:66
    // width:"100%"
    height: 20,
    maxHeight: 20,
  },
  imgWrapper: {
    width: 100,
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: 20,
  },
  img: {
    width: 100,

  },
  mainRow: {},
  tableWrapper: {

    overflow: 'auto',
  },
  wrapper: {
    width: '100%',
    padding: 15,
    paddingTop: 0,
    // marginTop: theme.spacing.unit * 3,
    overflowX: 'auto',
  },
  paper: {
    marginTop: 0,
    width: '100%',
    overflowX: 'auto',
    marginBottom: 0,
  },
  table: {
    maxWidth: '100%',
    width: '100%',
  },
}

export default sizeChartTableStyle
