import gql from 'graphql-tag'

export const FULL_MATCH_WETSUITS_SUMMER_INCH = gql`
    query wetsuitsConnection(
        $humanType: String!
        $afilCountry:String!
        $orderBy: WetsuitOrderByInput!

        $productAmount: Int!
        
        $priceMin: Float!
        $priceMax: Float!
        
        $frontzip: Boolean!
        $backzip: Boolean!
        $nozip: Boolean!

        $height: Float!
        $heightMin: Float!
        $heightMax: Float!

        $weight: Float!
        $weightMin: Float!
        $weightMax: Float!

        $chest: Float!
        $chestMin: Float!
        $chestMax: Float!

        $waist: Float!
        $waistMin: Float!
        $waistMax: Float!

        $hips: Float!
        $hipsMin: Float!
        $hipsMax: Float!

        $mystic: String!
        $orca: String!
        $billabong: String!
        $hurley: String!
        $ion: String!
        $neilpryde: String!
        $oneill: String!
        $quiksilver: String!
        $ripCurl: String!
        $roxy: String!
        $vissla: String!
        $xcel: String!
        $aquaSphere: String!
        $arena: String!
        $patagonia: String!

    ) {
        wetsuitsConnection(
            first:$productAmount
            orderBy:$orderBy
            where:{

            price_gt:$priceMin
            price_lt:$priceMax
                afilCountry:$afilCountry
            AND:[
                {
                    OR:[
                        {
                            frontzip:false
                            backzip:$backzip
                            zipless:false
                        },{
                            frontzip:$frontzip
                            backzip:false
                            zipless:false
                        }, {
                            frontzip:false
                            backzip:false
                            zipless:$nozip
                        },{
                            frontzip:false
                            backzip:false
                            zipless:false
                        },
                    ]
                },
                {
                    OR:[
                        {
                            thickness15:true
                        },
                        {
                            thickness20:true
                        },
                        {
                            thickness20:true
                            thickness15:true
                        },
                        {
                            thickness20:true
                            thickness10:true
                        },
                        {
                            thickness25:true
                        },
                        {
                            thickness30:true
                            thicknessR3:false
                            thickness40:false
                            thickness50:false
                        },
                        {
                            thicknessR1:true
                        },
                        {
                            thicknessR2:true
                        },
                        {
                            shorty:true
                        },
                        {
                            longJohnJane:true
                        },
                    ]},
                ,
                {
                    OR:[
                        {brand:{name_contains: $mystic}},
                        {brand:{name_contains: $orca}},
                        {brand:{name_contains: $billabong}},
                        {brand:{name_contains: $hurley}},
                        {brand:{name_contains: $ion}},
                        {brand:{name_contains: $neilpryde}},
                        {brand:{name_contains: $oneill}},
                        {brand:{name_contains: $quiksilver}},
                        {brand:{name_contains: $ripCurl}},
                        {brand:{name_contains: $roxy}},
                        {brand:{name_contains: $vissla}},
                        {brand:{name_contains: $xcel}},
                        {brand:{name_contains: $aquaSphere}},
                        {brand:{name_contains: $arena}},
                        {brand:{name_contains: $patagonia}},

                    ]
                }
            ]
            size:{
                humanType:{name:$humanType}
                OR:[
                    {
                        #                   1 WE N ≠ 0  WE XN  HE XN
                        weightMinInch_not:0
                        weightMinInch_lte:$weight weightMaxInch_gte: $weight
                        heightMinInch_lte:$height heightMaxInch_gte: $height
                    },
                    {
                        #                2  WE N ≠ 0  WE N  HE XN
                        weightMinInch_not:0
                        weightMaxInch:0
                        weightMinInch_lte:$weightMax weightMinInch_gte: $weightMin
                        heightMinInch_lte:$height heightMaxInch_gte: $height
                    },
                    {
                        #                3  WE N ≠ 0  WE XN  HE N
                        weightMinInch_not:0
                        weightMinInch_lte:$weight weightMaxInch_gte: $weight
                        heightMaxInch:0
                        heightMinInch_lte:$heightMax heightMinInch_gte: $heightMin
                    },
                    {
                        #                4  WE N ≠ 0  WE N  HE N
                        weightMinInch_not:0
                        weightMaxInch:0
                        weightMinInch_lte:$weightMax weightMinInch_gte: $weightMin
                        heightMaxInch:0
                        heightMinInch_lte:$heightMax heightMinInch_gte: $heightMin
                    },
                    {
                        #                5  WE N=0  HE XN
                        weightMinInch:0
                        heightMinInch_lte:$height heightMaxInch_gte: $height
                    },
                    {
                        #                6  WE N=0  HE N
                        weightMinInch:0
                        heightMaxInch:0
                        heightMinInch_lte:$heightMax heightMinInch_gte: $heightMin
                    },
                    {
                        #                7  WE N=0  HE XN  CH XN
                        weightMinInch:0
                        heightMinInch_lte:$height heightMaxInch_gte: $height
                        chestMinInch_lte:$chest chestMaxInch_gte: $chest
                    },
                    {
                        #                8  WE N=0  HE XN  CH N
                        weightMinInch:0
                        heightMinInch_lte:$height heightMaxInch_gte: $height
                        chestMaxInch:0
                        chestMinInch_lte:$chestMax chestMinInch_gte: $chestMin
                    },
                    {
                        #                9  WE N=0  HE N  CH XN
                        weightMinInch:0
                        heightMaxInch:0
                        heightMinInch_lte:$heightMax heightMinInch_gte: $heightMin
                        chestMinInch_lte:$chest chestMaxInch_gte: $chest
                    },
                    {
                        #                10  WE N=0  HE N  CH N
                        weightMinInch:0
                        heightMaxInch:0
                        heightMinInch_lte:$heightMax heightMinInch_gte: $heightMin
                        chestMaxInch:0
                        chestMinInch_lte:$chestMax chestMinInch_gte: $chestMin
                    },
                    {
                        #                11    HE XN  CH XN  WA XN
                        heightMinInch_lte:$height heightMaxInch_gte: $height
                        chestMinInch_lte:$chest chestMaxInch_gte: $chest
                        waistMinInch_lte:$waist waistMaxInch_gte: $waist
                    },
                    {
                        #                12    HE XN  CH N  WA XN
                        heightMinInch_lte:$height heightMaxInch_gte: $height
                        chestMaxInch:0
                        chestMinInch_lte:$chestMax chestMinInch_gte: $chestMin
                        waistMinInch_lte:$waist waistMaxInch_gte: $waist
                    },
                    {
                        #                13    HE N  CH XN  WA XN
                        heightMaxInch:0
                        heightMinInch_lte:$heightMax heightMinInch_gte: $heightMin
                        chestMinInch_lte:$chest chestMaxInch_gte: $chest
                        waistMinInch_lte:$waist waistMaxInch_gte: $waist
                    },
                    {
                        #                14    HE N  CH N  WA XN
                        heightMaxInch:0
                        heightMinInch_lte:$heightMax heightMinInch_gte: $heightMin
                        chestMaxInch:0
                        chestMinInch_lte:$chestMax chestMinInch_gte: $chestMin
                        waistMinInch_lte:$waist waistMaxInch_gte: $waist
                    },
                    {
                        #                15    HE XN  CH XN  WA N
                        heightMinInch_lte:$height heightMaxInch_gte: $height
                        chestMinInch_lte:$chest chestMaxInch_gte: $chest
                        waistMaxInch:0
                        waistMinInch_lte:$waistMax waistMinInch_gte: $waistMin
                    },
                    {
                        #                16    HE XN  CH N  WA N
                        heightMinInch_lte:$height heightMaxInch_gte: $height
                        chestMaxInch:0
                        chestMinInch_lte:$chestMax chestMinInch_gte: $chestMin
                        waistMaxInch:0
                        waistMinInch_lte:$waistMax waistMinInch_gte: $waistMin
                    },
                    {
                        #                17    HE N  CH XN  WA N
                        heightMaxInch:0
                        heightMinInch_lte:$heightMax heightMinInch_gte: $heightMin
                        chestMinInch_lte:$chest chestMaxInch_gte: $chest
                        waistMaxInch:0
                        waistMinInch_lte:$waistMax waistMinInch_gte: $waistMin
                    },
                    {
                        #                18    HE N  CH N  WA N
                        heightMinInch_lte:$height heightMaxInch_gte: $height
                        chestMaxInch:0
                        chestMinInch_lte:$chestMax chestMinInch_gte: $chestMin
                        waistMaxInch:0
                        waistMinInch_lte:$waistMax waistMinInch_gte: $waistMin
                    },
                    {
                        #                19    HE XN  CH XN  HI XN
                        heightMinInch_lte:$height heightMaxInch_gte: $height
                        chestMinInch_lte:$chest chestMaxInch_gte: $chest
                        hipsMinInch_lte:$hips hipsMaxInch_gte: $hips
                    },
                    {
                        #                20    HE XN  CH N  HI XN
                        heightMinInch_lte:$height heightMaxInch_gte: $height
                        chestMaxInch:0
                        chestMinInch_lte:$chestMax chestMinInch_gte: $chestMin
                        hipsMinInch_lte:$hips hipsMaxInch_gte: $hips
                    },
                    {
                        #                21    HE N  CH XN  HI XN
                        heightMaxInch:0
                        heightMinInch_lte:$heightMax heightMinInch_gte: $heightMin
                        chestMinInch_lte:$chest chestMaxInch_gte: $chest
                        hipsMinInch_lte:$hips hipsMaxInch_gte: $hips
                    },
                    {
                        #                22    HE N  CH N  HI XN
                        heightMaxInch:0
                        heightMinInch_lte:$heightMax heightMinInch_gte: $heightMin
                        chestMaxInch:0
                        chestMinInch_lte:$chestMax chestMinInch_gte: $chestMin
                        hipsMinInch_lte:$hips hipsMaxInch_gte: $hips
                    },
                    {
                        #                23    HE XN  CH XN  HI N
                        heightMinInch_lte:$height heightMaxInch_gte: $height
                        chestMinInch_lte:$chest chestMaxInch_gte: $chest
                        hipsMaxInch:0
                        hipsMinInch_lte:$hipsMax hipsMinInch_gte: $hipsMin
                    },
                    {
                        #                24    HE XN  CH N  HI N
                        heightMinInch_lte:$height heightMaxInch_gte: $height
                        chestMaxInch:0
                        chestMinInch_lte:$chestMax chestMinInch_gte: $chestMin
                        hipsMaxInch:0
                        hipsMinInch_lte:$hipsMax hipsMinInch_gte: $hipsMin
                    },
                    {
                        #                25    HE N  CH XN  HI N
                        heightMaxInch:0
                        heightMinInch_lte:$heightMax heightMinInch_gte: $heightMin
                        chestMinInch_lte:$chest chestMaxInch_gte: $chest
                        hipsMaxInch:0
                        hipsMinInch_lte:$hipsMax hipsMinInch_gte: $hipsMin
                    },
                    {
                        #                26    HE N  CH N  HI N
                        heightMaxInch:0
                        heightMinInch_lte:$heightMax heightMinInch_gte: $heightMin
                        chestMaxInch:0
                        chestMinInch_lte:$chestMax chestMinInch_gte: $chestMin
                        hipsMaxInch:0
                        hipsMinInch_lte:$hipsMax hipsMinInch_gte: $hipsMin
                    },
                ]
            }
        }){

            pageInfo{startCursor endCursor hasNextPage }
            aggregate{count}
            edges{
                cursor
                node{
                    id
                    size{
                        name
                    }

                    brand{name displayName}
                    updatedAt
                    productName
                    price
                    frontzip
                    backzip
                    zipless

                    afilLink
                    bigImage
                    smallImage
                    reducedPrice
                    oldPrice
                    currency
                    afilPartner
                }
            }
        }
    }
`