import React from 'react'
import { client } from '../../apollo/client'
import { CACHE_STATE } from '../../apollo/gql/CACHE_STATE'

import { FULL_MATCH_WETSUITS_ALL } from '../../apollo/gql/FULL_MATCH_WETSUITS_ALL'
import { FULL_MATCH_WETSUITS_TROPICS } from '../../apollo/gql/FULL_MATCH_WETSUITS_TROPICS'
import { FULL_MATCH_WETSUITS_SUMMER } from '../../apollo/gql/FULL_MATCH_WETSUITS_SUMMER'
import { FULL_MATCH_WETSUITS_AUTUMN } from '../../apollo/gql/FULL_MATCH_WETSUITS_AUTUMN'
import { FULL_MATCH_WETSUITS_WINTER } from '../../apollo/gql/FULL_MATCH_WETSUITS_WINTER'

import { FULL_MATCH_WETSUITS_ALL_INCH } from '../../apollo/gql/FULL_MATCH_WETSUITS_ALL_INCH'
import { FULL_MATCH_WETSUITS_TROPICS_INCH } from '../../apollo/gql/FULL_MATCH_WETSUITS_TROPICS_INCH'
import { FULL_MATCH_WETSUITS_SUMMER_INCH } from '../../apollo/gql/FULL_MATCH_WETSUITS_SUMMER_INCH'
import { FULL_MATCH_WETSUITS_AUTUMN_INCH } from '../../apollo/gql/FULL_MATCH_WETSUITS_AUTUMN_INCH'
import { FULL_MATCH_WETSUITS_WINTER_INCH } from '../../apollo/gql/FULL_MATCH_WETSUITS_WINTER_INCH'


// Sections for this page

const stdAmount = 12

const TriggerSearch = async () => {



    console.log('triggerSearch')

    const cache = await client.query({
      query: CACHE_STATE,
    })

    console.log('cache', cache)
    let search_result

    const humanType = cache.data.humanType.toUpperCase()
    const orderBy = cache.data.orderBy

    const priceMin = parseInt(cache.data.priceMin)
    const priceMax = parseInt(cache.data.priceMax)

    const frontzip = cache.data.frontzip
    const backzip = cache.data.backzip
    const nozip = cache.data.nozip

    let height = cache.data.height ? parseInt(cache.data.height) : 999
    let heightMin = cache.data.height ? parseInt(cache.data.height) - 2.5 : 999
    let heightMax = cache.data.height ? parseInt(cache.data.height) + 2.5 : 999
    if (cache.data.activeScale === 'INCH') {
      const heightFeet = cache.data.heightFeet ? parseInt(cache.data.heightFeet) * 12 : 999
      const heightInch = cache.data.heightInch ? parseInt(cache.data.heightInch) : 0
      height = heightFeet + heightInch
      heightMin = heightFeet + heightInch - 0.984252
      heightMax = heightFeet + heightInch + 0.984252
    }

    const weight = cache.data.weight ? parseInt(cache.data.weight) : 999
    let weightMin = cache.data.weight ? parseInt(cache.data.weight) - 4 : 999
    let weightMax = cache.data.weight ? parseInt(cache.data.weight) + 4 : 999

    if (cache.data.activeScale === 'INCH') {
      weightMin = cache.data.weight ? parseInt(cache.data.weight) - 8.81849 : 999
      weightMax = cache.data.weight ? parseInt(cache.data.weight) + 8.81849 : 999
    }

    const chest = cache.data.chest ? parseInt(cache.data.chest) : 999
    let chestMin = cache.data.chest ? parseInt(cache.data.chest) - 2 : 999
    let chestMax = cache.data.chest ? parseInt(cache.data.chest) + 2 : 999
    if (cache.data.activeScale === 'INCH') {
      chestMin = cache.data.chest ? parseInt(cache.data.chest) - 0.787402 : 999
      chestMax = cache.data.chest ? parseInt(cache.data.chest) + 0.787402 : 999
    }

    const waist = cache.data.waist ? parseInt(cache.data.waist) : 999
    let waistMin = cache.data.waist ? parseInt(cache.data.waist) - 2 : 999
    let waistMax = cache.data.waist ? parseInt(cache.data.waist) + 2 : 999
    if (cache.data.activeScale === 'INCH') {
      waistMin = cache.data.waist ? parseInt(cache.data.waist) - 0.787402 : 999
      waistMax = cache.data.waist ? parseInt(cache.data.waist) + 0.787402 : 999
    }

    const hips = cache.data.hips ? parseInt(cache.data.hips) : 999
    let hipsMin = cache.data.hips ? parseInt(cache.data.hips) - 2 : 999
    let hipsMax = cache.data.hips ? parseInt(cache.data.hips) + 2 : 999
    if (cache.data.activeScale === 'INCH') {
      hipsMin = cache.data.hips ? parseInt(cache.data.hips) - 0.787402 : 999
      hipsMax = cache.data.hips ? parseInt(cache.data.hips) + 0.787402 : 999
    }


    const allTemperatures = cache.data.allTemperatures
    const tropics = cache.data.tropics
    const summer = cache.data.summer
    const autumn = cache.data.autumn
    const winter = cache.data.winter

    const mystic = cache.data.mystic
    const orca = cache.data.orca
    const billabong = cache.data.billabong
    const hurley = cache.data.hurley
    const ion = cache.data.ion
    const neilpryde = cache.data.neilpryde
    const oneill = cache.data.oneill
    const quiksilver = cache.data.quiksilver
    const ripCurl = cache.data.ripCurl
    const roxy = cache.data.roxy
    const vissla = cache.data.vissla
    const xcel = cache.data.xcel
    const aquaSphere = cache.data.aquaSphere
    const arena = cache.data.arena
    const patagonia = cache.data.patagonia
    const productAmount = cache.data.productAmount
    const afilCountry = cache.data.activeAfilCountry

    const variables = {
      orderBy,
      productAmount,

      humanType,
      afilCountry,

      priceMin,
      priceMax,

      frontzip,
      backzip,
      nozip,

      height,
      heightMin,
      heightMax,

      weight,
      weightMin,
      weightMax,

      chest,
      chestMin,
      chestMax,

      waist,
      waistMin,
      waistMax,

      hips,
      hipsMin,
      hipsMax,

      mystic,
      orca,
      billabong,
      hurley,
      ion,
      neilpryde,
      oneill,
      quiksilver,
      ripCurl,
      roxy,
      vissla,
      xcel,
      aquaSphere,
      arena,
      patagonia,
    }

    let query
    query =
      cache.data.activeScale === 'INCH' ?
        allTemperatures ? query = FULL_MATCH_WETSUITS_ALL_INCH :
          tropics ? query = FULL_MATCH_WETSUITS_TROPICS_INCH :
            summer ? query = FULL_MATCH_WETSUITS_SUMMER_INCH :
              autumn ? query = FULL_MATCH_WETSUITS_AUTUMN_INCH :
                winter ? query = FULL_MATCH_WETSUITS_WINTER_INCH :
                  FULL_MATCH_WETSUITS_ALL_INCH
        :
        allTemperatures ? query = FULL_MATCH_WETSUITS_ALL :
          tropics ? query = FULL_MATCH_WETSUITS_TROPICS :
            summer ? query = FULL_MATCH_WETSUITS_SUMMER :
              autumn ? query = FULL_MATCH_WETSUITS_AUTUMN :
                winter ? query = FULL_MATCH_WETSUITS_WINTER :
                  FULL_MATCH_WETSUITS_ALL

    let logQuery
    logQuery =
      cache.data.activeScale === 'INCH' ?
        allTemperatures ? logQuery = 'FULL_MATCH_WETSUITS_ALL_INCH' :
          tropics ? logQuery = 'FULL_MATCH_WETSUITS_TROPICS_INCH' :
            summer ? logQuery = 'FULL_MATCH_WETSUITS_SUMMER_INCH' :
              autumn ? logQuery = 'FULL_MATCH_WETSUITS_AUTUMN_INCH' :
                winter ? logQuery = 'FULL_MATCH_WETSUITS_WINTER_INCH' :
                  'FULL_MATCH_WETSUITS_ALL_INCH'
        :
        allTemperatures ? logQuery = 'FULL_MATCH_WETSUITS_ALL' :
          tropics ? logQuery = 'FULL_MATCH_WETSUITS_TROPICS' :
            summer ? logQuery = 'FULL_MATCH_WETSUITS_SUMMER' :
              autumn ? logQuery = 'FULL_MATCH_WETSUITS_AUTUMN' :
                winter ? logQuery = 'FULL_MATCH_WETSUITS_WINTER' :
                  'FULL_MATCH_WETSUITS_ALL'


    console.log('logQuery:', logQuery)
    console.log('SEARCH:', variables)

    console.log('query:', query)

    search_result = await client.query({
      query,
      variables,
    })
    console.log('search_result:', search_result.data.wetsuitsConnection)
    // this.setState({
    //   results: search_result.data,
    //   hasNextPage: search_result.data.wetsuitsConnection.pageInfo.hasNextPage,
    // })


    await client.writeData(
      {
        data: {
          results: search_result.data.wetsuitsConnection,
        },
      },
    )
    await client.writeData(
      {
        data: {
          hasNextPage: search_result.data.wetsuitsConnection.pageInfo.hasNextPage,
        },
      },
    )
    // console.log('foo', foo)
    console.log('cache', cache.data)
    // console.log('search_result', search_result)
    // console.log('group', group)
    console.log('ori', search_result.data.sizes)



}

export default TriggerSearch