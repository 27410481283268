import React from 'react'
// nodejs library to set properties for components
import PropTypes from 'prop-types'
// nodejs library that concatenates classes
import classNames from 'classnames'
import InputLabel from '@material-ui/core/InputLabel'
// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles'
import FormControl from '@material-ui/core/FormControl'
import Input from '@material-ui/core/Input'
import InputAdornment from '@material-ui/core/InputAdornment'
import searchBarInputCustomStyle from "./searchBarInputCustomStyle";


function SearchBarInputCustom({...props}) {
    const {
        classes,
        formControlProps,
        labelText,
        id,
        labelProps,
        inputProps,
        error,
        white,
        inputRootCustomClasses,
        success,
    } = props

    const labelClasses = classNames({
        [' ' + classes.labelRootError]: error,
        [' ' + classes.labelRootSuccess]: success && !error,
    })
    const underlineClasses = classNames({
        [classes.underlineError]: error,
        [classes.underlineSuccess]: success && !error,
        [classes.underline]: true,
        [classes.whiteUnderline]: white,
    })
    const marginTop = classNames({
        [inputRootCustomClasses]: inputRootCustomClasses !== undefined,
    })
    const inputClasses = classNames({
        [classes.input]: true,
        [classes.whiteInput]: white,
    })
    var formControlClasses
    if (formControlProps !== undefined) {
        formControlClasses = classNames(
            formControlProps.className,
            classes.formControl,
        )
    } else {
        formControlClasses = classes.formControl
    }
    return (
        <form className={classes.root} autoComplete="off">
            <FormControl className={classes.formControl}
                         style={props.small ? {maxWidth: 50, marginLeft: 5, minWidth: 10} : {}}>
                {labelText !== undefined ? (
                    <InputLabel

                        htmlFor={id}

                        // hier nochmal prüfen ob man den Input component in den inputAdornment mit reinnehmen kann? um auto focus zu verhindern
                        disablePointerEvents
                    >
                        {labelText}
                    </InputLabel>
                ) : null}
                <Input
                    placeholder={props.placeholder ? props.placeholder : null}
                    classes={{
                        input: inputClasses,
                        root: marginTop,
                        disabled: classes.disabled,
                        underline: underlineClasses,

                    }}

                    id={id}
                    {...inputProps}
                    startAdornment={
                        props.inputAdorm ? <InputAdornment position="start"></InputAdornment> : null
                    }
                />
            </FormControl>
        </form>
    )
}


SearchBarInputCustom.defaultProps = {
    small: false,
    inputAdorm: true,
    placeholder: false,

}

SearchBarInputCustom.propTypes = {
    small: PropTypes.bool,
    classes: PropTypes.object.isRequired,
    labelText: PropTypes.node,
    labelProps: PropTypes.object,
    id: PropTypes.string,
    inputProps: PropTypes.object,
    formControlProps: PropTypes.object,
    inputRootCustomClasses: PropTypes.string,
    error: PropTypes.bool,
    success: PropTypes.bool,
    white: PropTypes.bool,
}

export default withStyles(searchBarInputCustomStyle)(SearchBarInputCustom)
