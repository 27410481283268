import { dangerColor, poppinsHeader, primaryColor } from 'assets/jss/material-kit-react.jsx'

const wetsuitResultsStyle = {
  root: {
    display: 'flex',

    width: '100%',
    flex: 1,
    justifyContent: 'center',
    // backgroundColor: '#dce775',
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  noResultswrapper:{
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
  },
  afilPartnerContainer:{
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'row',
  },
  afilPartnerWrapper:{
    // border: 'solid #a1a1a1 1px',
    marginTop: 15,
    // backgroundColor: '#a1a1a1',
    // borderRadius: 99,
    paddingLeft: 6,
    paddingRight: 6,
    marginRight: 5,
  },
  noResultswrapperFont:{
    fontWeight: 'bold', marginBottom: 0, paddingBottom: 0
  },
  price: {
    ...poppinsHeader,
    margin: 0,
    padding: 0,
    fontSize: 18,
    fontWeight: 'bold',
    color: primaryColor,

  },
  priceReduced: {
    color: dangerColor,
  },
  oldPrice: {
    textDecoration: 'line-through',
  },
  image: {

    maxHeight: 200,
    maxwidth: 300,
    marginTop: 15,
    objectFit: 'contain',
  },

  priceBrandWrapper: {
    marginTop: 10,
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    height: 30,
    overflow: 'hidden',
    // backgroundColor: 'khaki',
  },
  SizeSubWrapper: {
    backgroundColor: '#1a1a1a',
    padding: 5,
    paddingLeft: 6,
    paddingRight: 6,

  },
  sizeAndChartLinkWrapper: {

    marginTop: 10,
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    justifyContent: 'flex-start',
    alignItems: 'center',


    overflow: 'hidden',
    // backgroundColor: 'khaki',
  },

  sizeAndChartLinkWrapperItemLink:{
    margin: 0, padding: 0, display: 'flex', flexDirection: 'row', justifyContent: 'center',
    alignItems: 'center',
  },

  sizeAndChartLinkWrapperItem: {

    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    paddingTop: 7,
    paddingBottom: 7,
    transition: '0.3s ease',
    '&:hover': {
      backgroundColor: primaryColor,
    },
  },
  productNameWrapper: {

    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    paddingLeft: 5,
    paddingRight: 5,
    minHeight: 107,
    // backgroundColor: 'khaki',
  },
  productNameWrapperSpan:{
    border: 'solid #1a1a1a 1px',
    borderRadius: 99,
    paddingLeft: 6,
    paddingRight: 6,
    // marginRight: 5,
    marginLeft: 8,
    fontWeight: '600',
    fontSize: 13,
  },
  productFont: {
    ...poppinsHeader,
    textTransform: 'inherit',
    letterSpacing: 1,
    fontSize: 18,
    margin: 0,

  },
  productName: {
    ...poppinsHeader,
    textTransform: 'inherit',
    letterSpacing: 1,
    fontSize: 17,
    margin: 0,
    marginTop: 15,
    // fontFamily: '\'Poppins\', sans-serif',
  },
  chipFont: {
    textTransform: 'uppercase',
    fontSize: 12,
  },
  product: {
    // backgroundColor: 'khaki',
    width: 265,
    overflow: 'hidden',
    flexDirection: 'column',
    justifyContent: 'center',
    border: '1px solid black',
    marginRight: 15,
    marginBottom: 15,
    transition: '0.3s ease',
    '&:hover': {
      boxShadow: '0 4px 20px 0px rgba(0, 0, 0, 0.2)',

    },
    '@media (max-width: 1340px)': {
      width: 244,

    },
    '@media (max-width: 480px)': {
      marginRight: 0,
      width: 320,
    },
  },
  productLinkWrapper:{
    width: '100%', display: 'flex', flexDirection: 'column'
  }

}

export default wetsuitResultsStyle
