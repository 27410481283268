const finderPageStyle = {
  sidebarRoot: {
    display: 'flex',
    // backgroundColor: 'khaki',
    minWidth: 420,
    maxWidth: 420,
    marginRight: 20,
    marginBottom: 20,
    '@media (max-width: 768px)': {
      width: '100%',
      minWidth: 0,
      maxWidth: '100%',
      marginRight: 0,
    },
  },
  mainRightRoot: {
    display: 'flex',
    width: '70%',
    // backgroundColor: 'maroon',
    minHeight: 650,
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    '@media (max-width: 768px)': {
      width: '100%',
    },
  },
}

export default finderPageStyle
