import React from 'react'
// nodejs library to set properties for components
import PropTypes from 'prop-types'
// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles'

import badgeStyle from 'assets/jss/material-kit-react/components/badgeStyle.jsx'
import finderPageStyle from './finderPageStyle'



function SidebarLeft({ ...props }) {
  const { classes, color, children } = props
  return (
    <span className={classes.sidebarRoot}>{children}</span>
  )
}

SidebarLeft.defaultProps = {
  color: 'gray',
}

SidebarLeft.propTypes = {
  classes: PropTypes.object.isRequired,

}

export default withStyles(finderPageStyle)(SidebarLeft)
