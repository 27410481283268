import React from 'react'
// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles'
// @material-ui/icons
import instagramSectionStyle from './instagramSectionStyle.jsx'
import classNames from 'classnames'

import { graphql, useStaticQuery } from 'gatsby'
import ReactRevealFadeIn from '../../components/ReactRevealFadeIn/ReactRevealFadeIn'
import Section from '../Section/Section'
import SectionHeader from '../SectionHeader/SectionHeader'


const InstagramSection = props => {


  const data = useStaticQuery(graphql`
    query{
      instaNode{username}
      allInstaNode(limit:4){

        edges{
          node{
            id
            caption
            preview
          }
        }
      }
    }
  `)

  const { classes } = props


  const instagramImage = (insta) =>
    <ReactRevealFadeIn>
    <div className={classes.articleWrapper}>
      <div className={classes.linkClass}>
        <a href={'https://www.instagram.com/p/' + insta.node.id} rel={"noopener noreferrer"}  target={"_blank"}>
          <img
            className={classNames(classes.imgStyle, classes.imageDarkenOnHover)}
            src={insta.node.preview}
            alt={insta.node.caption}

          />
        </a>

      </div>
    </div>
    </ReactRevealFadeIn>

  return (
    <Section backgroundColor={'#e3eff0'} paddingBottom={100}>


        <SectionHeader
          header={'Instagram'}
          insta
          instalink={`https://www.instagram.com/${data.instaNode.username}`}
          subHeader={`@${data.instaNode.username}`}
          subHeaderSize={36}
        />
        <div className={classes.wrapper}>
          <div className={classNames(classes.subWrapper, classes.first)}>

            {data.allInstaNode.edges.slice(0, 2).map(insta => {
              return (
                instagramImage(insta)
              )
            })}
          </div>

          <div className={classNames(classes.subWrapper, classes.second)}>

            {data.allInstaNode.edges.slice(2, 4).map(insta => {
              return (
                instagramImage(insta)
              )
            })}
          </div>
        </div>




    </Section>
  )

}

export default withStyles(instagramSectionStyle)(InstagramSection)
