import React from 'react'

import withStyles from '@material-ui/core/styles/withStyles'
import SizeTable from '../../components/SizeTable/SizeTable'
import { primaryColor } from '../../assets/jss/material-kit-react'
import SizeChartTable from '../BrandSizeChart/SizeChartTable'

// import BlogSection from '../../elements/RecentBlogPosts/RecentBlogPosts'

// Sections for this page
const style = {
  root: {
    display: 'flex',
    width: '100%',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    flexDirection: 'column',
    marginBottom: 70,
  },
  linkWrapper: {
    display: 'flex',
    padding: 7,
    paddingLeft: 15,
    paddingRight: 5,
    justifyContent: 'center',
    alignItems: 'center',
    height: 20,
    backgroundColor: '#1a1a1a',
    transition:"all 0.3s ease",
    '&:hover': {

      backgroundColor: primaryColor,

    },
  },
}

const DisplaySizes = props => {


  const { classes, brandname, sizes, actLang } = props


  return (
    <div className={classes.root}>
      {/*{JSON.stringify(sizes)}*/}
      <div style={{
        display: 'flex',
        width: '100%',
        justifyContent: 'space-between',
        alignItems: 'center',
        flexWrap: 'wrap',
        flexDirection: 'row',
      }}>
        <h3 style={{ margin: 0, padding: 0 }}>{brandname ? brandname.toUpperCase() : null} <span
          style={{
            fontSize: 18,
            fontFamily: '\'Poppins\', sans-serif',
            textTransform: 'uppercase',
            letterSpacing: 3,
            flexGrow: 1,
          }}>({props.ht}-{props.activeScale})</span>

        </h3>
        <a href={
          actLang === 'de' ?
            '/de/groessentabellen/' + brandname.replace(/\s/g, '-').replace(/'/g, '-') :
            '/sizecharts/' + brandname.replace(/\s/g, '-').replace(/'/g, '-')
        } target={'_blank'}
           className={classes.linkWrapper}
        >
        <span style={{
          fontSize: 12,
          fontFamily: '\'Poppins\', sans-serif',
          textTransform: 'uppercase',
          letterSpacing: 5,
          color: 'white',
        }}>
          {actLang === 'de' ? 'Größentabelle' : 'Sizechart'} ↝
        </span>
        </a>
      </div>


      <SizeChartTable
        inch={props.inch}
        chartSizes={sizes}
        actLang={props.actLang}
        activeScale={props.activeScale}
        ht={props.ht}
        onlyChart
      />

      {sizes ?
        sizes.map((size) => {


          // brand.brand.map(size=>{
          //   return (
          //     <p>
          //       {item.brand.name.toUpperCase()} ###
          //       Size_{item.name} ###
          //       Height_{item.heightMin} -
          //       {item.heightMax}

          //
          //     </p>
          //   )
          // })
        }) : null
      }
    </div>
  )

}

export default withStyles(style)(DisplaySizes)
