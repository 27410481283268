import gql from 'graphql-tag'

export const FULL_MATCH_WETSUITS_ALL = gql`
    query wetsuitsConnection(
        $humanType: String!
        $afilCountry:String!
        $orderBy: WetsuitOrderByInput!

        $productAmount: Int!

        $priceMin: Float!
        $priceMax: Float!

        $frontzip: Boolean!
        $backzip: Boolean!
        $nozip: Boolean!

        $height: Float!
        $heightMin: Float!
        $heightMax: Float!

        $weight: Float!
        $weightMin: Float!
        $weightMax: Float!

        $chest: Float!
        $chestMin: Float!
        $chestMax: Float!

        $waist: Float!
        $waistMin: Float!
        $waistMax: Float!

        $hips: Float!
        $hipsMin: Float!
        $hipsMax: Float!

        $mystic: String!
        $orca: String!
        $billabong: String!
        $hurley: String!
        $ion: String!
        $neilpryde: String!
        $oneill: String!
        $quiksilver: String!
        $ripCurl: String!
        $roxy: String!
        $vissla: String!
        $xcel: String!
        $aquaSphere: String!
        $arena: String!
        $patagonia: String!

    ) {
        wetsuitsConnection(
            first:$productAmount
            orderBy:$orderBy
            where:{
                price_gt:$priceMin
                price_lt:$priceMax
                afilCountry:$afilCountry
                AND:[
                    {
                        OR:[
                            {brand:{name_contains: $mystic}},
                            {brand:{name_contains: $orca}},
                            {brand:{name_contains: $billabong}},
                            {brand:{name_contains: $hurley}},
                            {brand:{name_contains: $ion}},
                            {brand:{name_contains: $neilpryde}},
                            {brand:{name_contains: $oneill}},
                            {brand:{name_contains: $quiksilver}},
                            {brand:{name_contains: $ripCurl}},
                            {brand:{name_contains: $roxy}},
                            {brand:{name_contains: $vissla}},
                            {brand:{name_contains: $xcel}},
                            {brand:{name_contains: $aquaSphere}},
                            {brand:{name_contains: $arena}},
                            {brand:{name_contains: $patagonia}},
                        ]},
                    {
                        OR:[
                            {
                                frontzip:false
                                backzip:$backzip
                                zipless:false
                            },{
                                frontzip:$frontzip
                                backzip:false
                                zipless:false
                            }, {
                                frontzip:false
                                backzip:false
                                zipless:$nozip
                            },{
                                frontzip:false
                                backzip:false
                                zipless:false
                            }]
                    }
                ]

                size:{
                    humanType:{name:$humanType}
                    OR:[

                        {
                            #                   1 WE N ≠ 0  WE XN  HE XN
                            weightMin_not:0
                            weightMin_lte:$weight weightMax_gte: $weight
                            heightMin_lte:$height heightMax_gte: $height
                        },
                        {
                            #                2  WE N ≠ 0  WE N  HE XN
                            weightMin_not:0
                            weightMax:0
                            weightMin_lte:$weightMax weightMin_gte: $weightMin
                            heightMin_lte:$height heightMax_gte: $height
                        },
                        {
                            #                3  WE N ≠ 0  WE XN  HE N
                            weightMin_not:0
                            weightMin_lte:$weight weightMax_gte: $weight
                            heightMax:0
                            heightMin_lte:$heightMax heightMin_gte: $heightMin
                        },
                        {
                            #                4  WE N ≠ 0  WE N  HE N
                            weightMin_not:0
                            weightMax:0
                            weightMin_lte:$weightMax weightMin_gte: $weightMin
                            heightMax:0
                            heightMin_lte:$heightMax heightMin_gte: $heightMin
                        },
                        {
                            #                5  WE N=0  HE XN
                            weightMin:0
                            heightMin_lte:$height heightMax_gte: $height
                        },
                        {
                            #                6  WE N=0  HE N
                            weightMin:0
                            heightMax:0
                            heightMin_lte:$heightMax heightMin_gte: $heightMin
                        },
                        {
                            #                7  WE N=0  HE XN  CH XN
                            weightMin:0
                            heightMin_lte:$height heightMax_gte: $height
                            chestMin_lte:$chest chestMax_gte: $chest
                        },
                        {
                            #                8  WE N=0  HE XN  CH N
                            weightMin:0
                            heightMin_lte:$height heightMax_gte: $height
                            chestMax:0
                            chestMin_lte:$chestMax chestMin_gte: $chestMin
                        },
                        {
                            #                9  WE N=0  HE N  CH XN
                            weightMin:0
                            heightMax:0
                            heightMin_lte:$heightMax heightMin_gte: $heightMin
                            chestMin_lte:$chest chestMax_gte: $chest
                        },
                        {
                            #                10  WE N=0  HE N  CH N
                            weightMin:0
                            heightMax:0
                            heightMin_lte:$heightMax heightMin_gte: $heightMin
                            chestMax:0
                            chestMin_lte:$chestMax chestMin_gte: $chestMin
                        },
                        {
                            #                11    HE XN  CH XN  WA XN
                            heightMin_lte:$height heightMax_gte: $height
                            chestMin_lte:$chest chestMax_gte: $chest
                            waistMin_lte:$waist waistMax_gte: $waist
                        },
                        {
                            #                12    HE XN  CH N  WA XN
                            heightMin_lte:$height heightMax_gte: $height
                            chestMax:0
                            chestMin_lte:$chestMax chestMin_gte: $chestMin
                            waistMin_lte:$waist waistMax_gte: $waist
                        },
                        {
                            #                13    HE N  CH XN  WA XN
                            heightMax:0
                            heightMin_lte:$heightMax heightMin_gte: $heightMin
                            chestMin_lte:$chest chestMax_gte: $chest
                            waistMin_lte:$waist waistMax_gte: $waist
                        },
                        {
                            #                14    HE N  CH N  WA XN
                            heightMax:0
                            heightMin_lte:$heightMax heightMin_gte: $heightMin
                            chestMax:0
                            chestMin_lte:$chestMax chestMin_gte: $chestMin
                            waistMin_lte:$waist waistMax_gte: $waist
                        },
                        {
                            #                15    HE XN  CH XN  WA N
                            heightMin_lte:$height heightMax_gte: $height
                            chestMin_lte:$chest chestMax_gte: $chest
                            waistMax:0
                            waistMin_lte:$waistMax waistMin_gte: $waistMin
                        },
                        {
                            #                16    HE XN  CH N  WA N
                            heightMin_lte:$height heightMax_gte: $height
                            chestMax:0
                            chestMin_lte:$chestMax chestMin_gte: $chestMin
                            waistMax:0
                            waistMin_lte:$waistMax waistMin_gte: $waistMin
                        },
                        {
                            #                17    HE N  CH XN  WA N
                            heightMax:0
                            heightMin_lte:$heightMax heightMin_gte: $heightMin
                            chestMin_lte:$chest chestMax_gte: $chest
                            waistMax:0
                            waistMin_lte:$waistMax waistMin_gte: $waistMin
                        },
                        {
                            #                18    HE N  CH N  WA N
                            heightMin_lte:$height heightMax_gte: $height
                            chestMax:0
                            chestMin_lte:$chestMax chestMin_gte: $chestMin
                            waistMax:0
                            waistMin_lte:$waistMax waistMin_gte: $waistMin
                        },
                        {
                            #                19    HE XN  CH XN  HI XN
                            heightMin_lte:$height heightMax_gte: $height
                            chestMin_lte:$chest chestMax_gte: $chest
                            hipsMin_lte:$hips hipsMax_gte: $hips
                        },
                        {
                            #                20    HE XN  CH N  HI XN
                            heightMin_lte:$height heightMax_gte: $height
                            chestMax:0
                            chestMin_lte:$chestMax chestMin_gte: $chestMin
                            hipsMin_lte:$hips hipsMax_gte: $hips
                        },
                        {
                            #                21    HE N  CH XN  HI XN
                            heightMax:0
                            heightMin_lte:$heightMax heightMin_gte: $heightMin
                            chestMin_lte:$chest chestMax_gte: $chest
                            hipsMin_lte:$hips hipsMax_gte: $hips
                        },
                        {
                            #                22    HE N  CH N  HI XN
                            heightMax:0
                            heightMin_lte:$heightMax heightMin_gte: $heightMin
                            chestMax:0
                            chestMin_lte:$chestMax chestMin_gte: $chestMin
                            hipsMin_lte:$hips hipsMax_gte: $hips
                        },
                        {
                            #                23    HE XN  CH XN  HI N
                            heightMin_lte:$height heightMax_gte: $height
                            chestMin_lte:$chest chestMax_gte: $chest
                            hipsMax:0
                            hipsMin_lte:$hipsMax hipsMin_gte: $hipsMin
                        },
                        {
                            #                24    HE XN  CH N  HI N
                            heightMin_lte:$height heightMax_gte: $height
                            chestMax:0
                            chestMin_lte:$chestMax chestMin_gte: $chestMin
                            hipsMax:0
                            hipsMin_lte:$hipsMax hipsMin_gte: $hipsMin
                        },
                        {
                            #                25    HE N  CH XN  HI N
                            heightMax:0
                            heightMin_lte:$heightMax heightMin_gte: $heightMin
                            chestMin_lte:$chest chestMax_gte: $chest
                            hipsMax:0
                            hipsMin_lte:$hipsMax hipsMin_gte: $hipsMin
                        },
                        {
                            #                26    HE N  CH N  HI N
                            heightMax:0
                            heightMin_lte:$heightMax heightMin_gte: $heightMin
                            chestMax:0
                            chestMin_lte:$chestMax chestMin_gte: $chestMin
                            hipsMax:0
                            hipsMin_lte:$hipsMax hipsMin_gte: $hipsMin
                        },
                    ]
                }
            }){
            aggregate{count}
            pageInfo{startCursor endCursor hasNextPage }
            edges{
               
                cursor
                node{
                 id
                    size{
                        name
                    }
                    brand{
                        name
                        displayName
                    }
                    updatedAt
                    productName
                    price
                    frontzip
                    backzip
                    zipless
                    afilLink
                    bigImage
                    smallImage
                    reducedPrice
                    oldPrice
                    currency
                    afilPartner
                }
            }

        }
    }


`