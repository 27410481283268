import React from 'react'
import withStyles from '@material-ui/core/styles/withStyles'
import searchBarStyle from './searchBarStyle'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import Collapse from '@material-ui/core/Collapse'
import InboxIcon from '@material-ui/icons/Euro'
import ExpandLess from '@material-ui/icons/ExpandLess'
import ExpandMore from '@material-ui/icons/ExpandMore'
import Slider from '@material-ui/core/Slider'
import { client } from '../../apollo/client'
import ValuePreview from './ValuePreview/ValuePreview'

const humanTypes = [{ humanType: 'men' }, { humanType: 'women' }, { humanType: 'kids' }]

function valuetext(value) {
  return `${value}°C`
}

class Price extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      open: false,
      value: [0, 500],

    }
  }

  handleClick = () => {
    if (this.state.open) {
      this.setState({
        open: false,
      })
    } else {
      this.setState({
        open: true,
      })
    }

  }
  handleChange = async (event, newValue) => {
    // this.setState({
    //   value:event.target.value
    // });
    this.setState({
      value: [newValue[0], newValue[1]],
    })

    client.writeData({
      data: {
        priceMin: newValue[0],
        priceMax: newValue[1],
      },
    })

    // const get= await client.cache.readQuery({ query: gql`
    //         query  {
    //             priceMin @client
    //         }
    //   ` })

    // console.log(get)
  }


  render() {

    const { classes,title } = this.props
    const { open, value, secondValue } = this.state

    return (
      <div style={{ color: 'black' }}>
        <ListItem button onClick={() => this.handleClick()}>
          <ListItemIcon>
            <InboxIcon/>
          </ListItemIcon>
          <ListItemText primary={title}/>
          {open ?
            <ValuePreview price values={[
              { title: value[0].toString() + ' - ' },
              { title: value[1].toString() },
            ]}>
              <ExpandLess style={{ color: 'gray' }}/>
            </ValuePreview> :
            <ValuePreview price values={[
              { title: value[0].toString() + ' - ' },
              { title: value[1].toString() },
            ]}>
              <ExpandMore style={{ color: 'gray' }}/>
            </ValuePreview>}
        </ListItem>
        <Collapse in={open} timeout="auto" unmountOnExit>


          {/*<Typography id="range-slider" gutterBottom>*/}
          {/*  Preis Range*/}
          {/*</Typography>*/}
          <div style={{
            display: 'flex',
            height: 80,
            marginTop: 25,
            width: '100%',
            justifyContent: 'center',
            alignItems: 'center',
          }}>
            <Slider
              value={value}
              onChange={(value, newValue) => this.handleChange(value, newValue)}
              max={1200}
              valueLabelDisplay="on"
              aria-labelledby="range-slider"
              style={{ maxWidth: '80%',  backgroundColor: '#f9f9f9' }}
            />
          </div>


        </Collapse>

      </div>
    )
  }
}

export default withStyles(searchBarStyle)(Price)
