import React from 'react'
import withStyles from '@material-ui/core/styles/withStyles'

import wetsuitFinderStyle from './wetsuitFinderStyle'
import Loader from './Loader'
import LoadMoreSpinner from './LoadMoreSpinner'
import WetsuitResults from './Display/WetsuitResults'
import SearchBarProvider from '../../elements/SearchBar/SearchBarProvider'
import SidebarLeft from '../../components/FinderPage/SidebarLeft'
import MainRight from '../../components/FinderPage/MainRight'
import SectionZweiteilig from '../../components/SectionZweiteilig/SectionZweiteilig'
import HasNextPage from './HasNextPage'


// Sections for this page

const stdAmount = 12

class WetsuitFinderContent extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            humanType: 'men',
            activeScale: 'cm',

            productAmount: stdAmount,
            results: null,

            displayShowMore: false,
            hasNextPage: false,
        }
    }


    render() {
        const { actLang, transl,onSBClick} = this.props
        return (
            <SectionZweiteilig paddingTop={30}
                               flexDirRow={true}
                               innerStyles={{maxWidth: 1900}}

            >
                <SidebarLeft>
                    <SearchBarProvider
                        transl={transl}
                        onSBClick={onSBClick}
                    />
                </SidebarLeft>
                <MainRight>
                    <Loader/>
                    <WetsuitResults
                        transl={transl}
                        actLang={actLang}
                    />
                    <LoadMoreSpinner/>

                    <HasNextPage
                        searchButtonText={transl.showMoreButtonLabel}
                    />
                </MainRight>
            </SectionZweiteilig>
        )
    }
}

export default withStyles(wetsuitFinderStyle)(WetsuitFinderContent)