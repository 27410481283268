import React from 'react'
// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles'
import buttonScrollDownStyle from './buttonScrollDownStyle'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'
import {animateScroll as scroll} from 'react-scroll'
// @material-ui/icons


const ButtonScrollDown = props => {

    const {classes} = props

    return (
        <button
            className={classes.root}
            onClick={() => {
                scroll.scrollTo(window.innerHeight, {
                    duration: 1200,
                    delay: 0,
                    smooth: 'easeInOutQuart',
                })
            }}
        >
            <KeyboardArrowDownIcon className={classes.icon}/>
        </button>
    )

}

export default withStyles(buttonScrollDownStyle)(ButtonScrollDown)
