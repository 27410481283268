import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import { client } from '../../apollo/client'
import { useQuery } from '@apollo/react-hooks'
import { CACHE_STATE } from '../../apollo/gql/CACHE_STATE'

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  formControl: {
    margin: 5,
    minWidth: 120,
    width: '100%',
  },
  selectEmpty: {
    marginTop: 5,
  },
}))

export default function ScaleChooserHook(props) {
  const classes = useStyles()
  const [values, setValues] = React.useState({
    activeScale: 'CM',
    name: 'Sortierung',
  })

  const inputLabel = React.useRef(null)
  const [labelWidth, setLabelWidth] = React.useState(0)
  React.useEffect(() => {
    setLabelWidth(inputLabel.current.offsetWidth)
  }, [])

  const handleChange = event => {
    setValues(oldValues => ({
      ...oldValues,
      [event.target.name]: event.target.value,
    }))

    client.writeData(
      {
        data: { activeScale: event.target.value },
      },
    )
  }
  const { loading, error, data } = useQuery(CACHE_STATE)

  return (
    <form className={classes.root} autoComplete="off">
      <FormControl variant="outlined" className={classes.formControl}>
        <InputLabel ref={inputLabel} htmlFor="outlined-Sortierung-simple">
          {props.title}
        </InputLabel>
        <Select
          value={data ? data.activeScale : values.activeScale}
          onChange={handleChange}
          labelWidth={labelWidth}
          inputProps={{
            name: 'activeScale',
            id: 'outlined-Maßeinheit-simple',
          }}
        >

          <MenuItem value={'CM'}>CM</MenuItem>
          <MenuItem value={'INCH'}>INCH</MenuItem>

        </Select>
      </FormControl>

    </form>
  )
}
