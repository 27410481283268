import React from 'react'
import withStyles from '@material-ui/core/styles/withStyles'
import searchBarStyle from './searchBarStyle'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import Collapse from '@material-ui/core/Collapse'
import InboxIcon from '@material-ui/icons/MoreHoriz'
import ExpandLess from '@material-ui/icons/ExpandLess'
import ExpandMore from '@material-ui/icons/ExpandMore'

import FormControl from '@material-ui/core/FormControl'
import FormGroup from '@material-ui/core/FormGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormHelperText from '@material-ui/core/FormHelperText'
import Checkbox from '@material-ui/core/Checkbox'
import { client } from '../../apollo/client'
import ValuePreview from './ValuePreview/ValuePreview'

class Ziptype extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      open: false,
      frontzip: true,
      backzip: true,
      nozip: true,
    }
  }

  handleClick = () => {
    if (this.state.open) {
      this.setState({
        open: false,
      })
    } else {
      this.setState({
        open: true,
      })
    }
  }

  handleChange = prop => event => {
    this.setState({ ...this.state, [event.target.value]: event.target.checked }, () => {
      console.log(this.state)
    })
    client.writeData({
      data: {
        [prop]: event.target.checked,
      },
    })
  }

  render() {
    const {
      classes,
      title,
      searchSectionZipTypeBackZip,
      searchSectionZipTypeFrontZip,
      searchSectionZipTypeNoZip,
    } = this.props
    const { open, frontzip, backzip, nozip } = this.state

    return (
      <div style={{ color: 'black' }}>
        <ListItem  button onClick={() => this.handleClick()}
                  // style={open?{backgroundColor:"#f9f9f9"}:{}}
        >
          <ListItemIcon>
            <InboxIcon/>
          </ListItemIcon>
          <ListItemText primary={title}/>
          {open ?
            <ValuePreview values={[
              frontzip ? { title: 'Front' } : null,
              backzip ? { title: 'Back' } : null,
              nozip ? { title: 'Zipless' } : null,
              !frontzip && !backzip && !nozip ? { title: 'None' } : null,
            ]}>
              <ExpandLess style={{ color: 'gray' }}/></ValuePreview>
            :
            <ValuePreview values={[
              frontzip ? { title: 'Front' } : null,
              backzip ? { title: 'Back' } : null,
              nozip ? { title: 'Zipless' } : null,
              !frontzip && !backzip && !nozip ? { title: 'None' } : null,

            ]}><ExpandMore style={{ color: 'gray' }}/></ValuePreview>}
        </ListItem>
        <Collapse in={open} timeout="auto" unmountOnExit>
          <List component="div" disablePadding style={{
            margin: 0, padding: 0,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'flex-end',
            width: '100%',
          }}>
            <FormControl component="fieldset" className={classes.dropdownListWrapper}>
              <FormGroup >
                <FormControlLabel
                  control={<Checkbox color="primary" checked={frontzip} onChange={this.handleChange('frontzip')} value="frontzip"/>}
                  label={searchSectionZipTypeFrontZip}
                  className={classes.dropdownListItem}
                />
                <FormControlLabel
                  control={<Checkbox color="primary" checked={backzip} onChange={this.handleChange('backzip')} value="backzip"/>}
                  label={searchSectionZipTypeBackZip}
                  className={classes.dropdownListItem}
                />
                <FormControlLabel
                  control={
                    <Checkbox checked={nozip} color="primary"  onChange={this.handleChange('nozip')} value="nozip"/>
                  }
                  label={searchSectionZipTypeNoZip}
                  className={classes.dropdownListItem}
                />
              </FormGroup>
            </FormControl>
          </List>
        </Collapse>
      </div>
    )
  }
}

export default withStyles(searchBarStyle)(Ziptype)
