import React from 'react'
import {withStyles} from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import Slide from '@material-ui/core/Slide'
import SearchBarWetsuitFinder from '../SearchBarWetsuitFinder'
import {primaryColor} from 'assets/jss/material-kit-react.jsx'

const useStyles = {
    appBar: {
        top: 'auto',
        bottom: 0,
        backgroundColor: primaryColor,
    },
    appBarTop: {
        bottom: 'auto',
        top: 0,
        backgroundColor: primaryColor,
    },
    title: {
        height: '100%',
        justifyContent: 'flex-start',
        alignItems: 'center',
        color: 'white',
        flex: 1,
    },
}

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />
})

function FullScreenDialog(props) {
    const {classes} = props
    const [open, setOpen] = React.useState(false)

    const handleClickOpen = () => {
        setOpen(true)
    }

    const handleClose = () => {
        setOpen(false)
    }

    const triggerBoth = () => {
        props.onSBClick()
        handleClose()
    }


    return (
        <div style={{display: 'flex', width: '100%'}}>
            <div style={{
                display: 'flex',
                width: '100%',
                justifyContent: 'center',
                alignItems: 'center',
            }}>
                <Button
                    color="primary"
                    fullwidth
                    variant="outlined"
                    onClick={handleClickOpen}
                    style={{height: 60, width: "100%"}}
                >{props.transl.mobileSearchMenubuttonLabel}</Button>
            </div>

            <Dialog fullScreen open={open} onClose={handleClose} TransitionComponent={Transition}>
                <div style={{marginBottom: 50, marginTop: 70}}>
                    <SearchBarWetsuitFinder
                        searchSizesOnly={props.searchSizesOnly}
                        transl={props.transl}
                        triggerSearch={triggerBoth}
                        showButton={props.showButton}
                    />
                </div>

                <AppBar position="fixed" color="primary" className={classes.appBar}>
                    <Toolbar>

                        <div style={{
                            display: 'flex',
                            flex: 1,

                            width: '100%',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}>
                            <Button
                                fullWidth
                                color="inherit"
                                onClick={triggerBoth}
                                style={{
                                    display: 'flex',
                                    flex: 1,
                                    width: "100%",
                                    height: "100%",
                                }}
                            >
                                {props.transl.searchButtonTitle}
                            </Button>
                        </div>
                    </Toolbar>
                </AppBar>
                <AppBar position="fixed" color="primary" className={classes.appBarTop}>
                    <Toolbar>
                        <IconButton variant="outlined" edge="start" color="inherit" onClick={handleClose}
                                    aria-label="close">
                            <CloseIcon/>
                        </IconButton>

                    </Toolbar>
                </AppBar>


                {/*<List>*/}
                {/*  <ListItem button>*/}
                {/*    <ListItemText primary="Phone ringtone" secondary="Titania"/>*/}
                {/*  </ListItem>*/}
                {/*  <Divider/>*/}
                {/*  <ListItem button>*/}
                {/*    <ListItemText primary="Default notification ringtone" secondary="Tethys"/>*/}
                {/*  </ListItem>*/}
                {/*</List>*/}


            </Dialog>
        </div>
    )
}

export default withStyles(useStyles)(FullScreenDialog)