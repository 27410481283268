import React from 'react'
import { client } from '../../apollo/client'
import { CACHE_STATE } from '../../apollo/gql/CACHE_STATE'
import TriggerSearch from './TriggerSearch'


// Sections for this page

const stdAmount = 12

const TriggerSearchBar = async () => {

  console.log('TriggerSearchBar')


  await client.writeData(
    {
      data: { loading: true },
    },
  )

    await client.writeData(
      {
        data: {
          results: null,
          hasNextPage: false,
          productAmount: stdAmount,

        },
      },
    )

    await TriggerSearch()

    await client.writeData(
      {
        data: {
          loading: false,

        },
      },
    )



}

export default TriggerSearchBar