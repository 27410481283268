import React from 'react'
import withStyles from '@material-ui/core/styles/withStyles'
import searchBarStyle from '../searchBarStyle'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import Collapse from '@material-ui/core/Collapse'
import InboxIcon from '@material-ui/icons/Eco'
import ExpandLess from '@material-ui/icons/ExpandLess'
import ExpandMore from '@material-ui/icons/ExpandMore'
import _ from 'lodash'
import { graphql } from 'gatsby'
import { client } from '../../../apollo/client'
import FormControl from '@material-ui/core/FormControl'
import FormGroup from '@material-ui/core/FormGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'
import FormHelperText from '@material-ui/core/FormHelperText'
import { CACHE_STATE } from '../../../apollo/gql/CACHE_STATE'
import { Paper } from '@material-ui/core'


class Brands extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      counter: 0,
      open: false,
      data: null,
      brands: [
        { name: 'mystic', displayName: 'Mystic', checked: true },
        { name: 'orca', displayName: 'Orca', checked: true },
        { name: 'billabong', displayName: 'Billabong', checked: true },
        { name: 'hurley', displayName: 'Hurley', checked: true },
        { name: 'ion', displayName: 'Ion', checked: true },
        { name: 'neilpryde', displayName: 'NeilPryde', checked: true },
        { name: 'oneill', displayName: 'O\'Neill', checked: true },
        { name: 'quiksilver', displayName: 'Quiksilver', checked: true },
        { name: 'ripCurl', displayName: 'Rip Curl', checked: true },
        { name: 'roxy', displayName: 'Roxy', checked: true },
        { name: 'vissla', displayName: 'Vissla', checked: true },
        { name: 'xcel', displayName: 'Xcel', checked: true },
        { name: 'aquaSphere', displayName: 'Aqua Sphere', checked: true },
        { name: 'arena', displayName: 'Arena', checked: true },
        { name: 'patagonia', displayName: 'Patagonia', checked: true },
      ],

      mystic: true,
      orca: true,
      billabong: true,
      hurley: true,
      ion: true,
      neilpryde: true,
      oneill: true,
      quiksilver: true,
      ripCurl: true,
      roxy: true,
      vissla: true,
      xcel: true,
      aquaSphere: true,
      arena: true,
      patagonia: true,

      allBrands:false,
    }
  }



  handleClick = () => {
    if (this.state.open) {
      this.setState({
        open: false,
      })
    } else {
      this.setState({
        open: true,
      })
    }

  }
  handleChange = async event => {

    const index = _.findIndex(this.state.brands, function(o) {
      return o.name === event.target.value
    })

    console.log(event.target.value, index)
    console.log(event.target.value, event.target.checked)
    this.setState({
      ...this.state,
      [event.target.value]: event.target.checked,
    }, () => {

    })

    if (event.target.checked) {
      await client.writeData(
        {
          data: {
            [event.target.value]: this.state.brands[index].displayName,
          },
        },
      )
    } else {
      await client.writeData(
        {
          data: {
            [event.target.value]: 'xxxx',
          },
        },
      )
    }
    const cache = await client.query({
      query: CACHE_STATE,
    })
    console.log(cache.data)



  }

  render() {
    const { classes, title } = this.props
    const { open, brands } = this.state

    return (
      <div style={{ color: 'black' }}>
        <ListItem button onClick={() => this.handleClick()}>
          <ListItemIcon>
            <InboxIcon/>
          </ListItemIcon>
          <ListItemText primary={title}/>
          {open ? <ExpandLess style={{ color: 'gray' }}/> : <ExpandMore style={{ color: 'gray' }}/>}
        </ListItem>
        <Collapse in={open} timeout="auto" unmountOnExit>
          {/*<FormControlLabel*/}
          {/*  control={*/}
          {/*    <Switch*/}
          {/*      checked={this.state.allBrands}*/}
          {/*      onChange={()=>this.handleChangeAllBrands()}*/}
          {/*      value="checkedB"*/}
          {/*      color="primary"*/}
          {/*    />*/}
          {/*  }*/}
          {/*  label="Select All"*/}
          {/*/>*/}
          {/*<Paper style={{ maxHeight: 300, overflow: 'auto' }}>*/}
            <List component="div" disablePadding>
              {brands ?
                <FormControl component="fieldset" >
                  <FormGroup className={classes.dropdownListWrapperRow}>
                    {brands.map(brand =>

                        <FormControlLabel
                          control={<Checkbox
                            color="primary"
                            checked={this.state[brand.name]}
                            onChange={(value) => this.handleChange(value)} value={brand.name}/>}
                          label={brand.displayName}
                          className={classes.dropdownListItemRow}
                        />

                    )}

                  </FormGroup>

                </FormControl> : null}


            </List>
          {/*</Paper>*/}

        </Collapse>

      </div>
    )
  }


}

// export const query = graphql`
//     query{
//         a1main{
//             brands{
//                 name
//                 displayName
//
//
//             }
//         }
//     }
// `
export default withStyles(searchBarStyle)(Brands)
