import React from 'react'

import withStyles from '@material-ui/core/styles/withStyles'
import DisplaySizes from './DisplaySizes'

// import BlogSection from '../../elements/RecentBlogPosts/RecentBlogPosts'

// Sections for this page
const style = {
  root: {
    width: '100%',
    '@media (max-width: 1020px)': {
      marginTop: 60,
    },
  },
}

const DisplayBrands = props => {


  const { classes, data } = props

  return (
    <div className={classes.root}>
      {/*{JSON.stringify(sizes)}*/}

      {data ?
        data.map((item) => {
          return (
            <DisplaySizes
              inch={props.inch}
              ht={props.ht}
              activeScale={props.activeScale}
              brandname={item.brand}
              sizes={item.sizes}
              actLang={props.actLang}
            />
          )
          // brand.brand.map(size=>{
          //   return (
          //     <p>
          //       {item.brand.name.toUpperCase()} ###
          //       Size_{item.name} ###
          //       Height_{item.heightMin} -
          //       {item.heightMax}
          //       {/*### Weight_{item.weightMin} -*/}
          //       {/*{item.weightMax} -*/}
          //
          //     </p>
          //   )
          // })
        }) : null
      }
    </div>
  )

}

export default withStyles(style)(DisplayBrands)
